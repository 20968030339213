import store from "../../store/store";

export default class fileSlice {
  constructor(file, base64File, contentType, indexFile) {
    this.file = file;
    this.base64File = base64File;
    this.contentType = contentType;
    this.indexFile = indexFile;
    if (typeof indexFile === "undefined") {
      this.createIndexFile();
    }
  }

  file(file) {
    this.file = file;
  }

  base64File(base64File) {
    this.base64File = base64File;
  }

  contentType(contentType) {
    this.contentType = contentType;
  }

  createIndexFile() {
    return new Promise((resolve, reject) => {
      store
        .getItem("indexFile")
        .then((response) => {
          if (response === null) {
            store.setItem("indexFile", 0);
            this.indexFile = 0;
          } else {
            this.indexFile = response;
          }
          resolve(this.indexFile);
        })
        .catch(() => {
          reject();
        });
    });
  }

  addIndexFile() {
    return new Promise((resolve) => {
      store.setItem("indexFile", this.indexFile + 1).then((response) => {
        resolve(response);
      });
    });
  }

  setIndexFile(newIndex) {
    return new Promise((resolve) => {
      store.setItem("indexFile", newIndex).then((response) => {
        resolve(response);
      });
    });
  }

  saveFileBase64() {
    if (
      typeof this.file !== "undefined" &&
      typeof this.indexFile === "number" &&
      typeof this.contentType !== "undefined"
    ) {
      return new Promise((resolve) => {
        store.setItem("file-" + this.indexFile, this.base64File).then(() => {
          resolve({ keyFile: "file-" + this.indexFile });
        });
      });
    } else {
      throw new Error("File not Undefined");
    }
  }

  saveFileBlob() {
    if (
      typeof this.file !== "undefined" &&
      typeof this.indexFile === "number" &&
      typeof this.contentType !== "undefined"
    ) {
      return new Promise((resolve) => {
        store.setItem("file-" + this.indexFile, this.file).then(() => {
          resolve({ keyFile: "file-" + this.indexFile });
        });
      });
    } else {
      throw new Error("File not Undefined");
    }
  }

  saveFileParts() {
    if (
      typeof this.file !== "undefined" &&
      typeof this.indexFile === "number" &&
      typeof this.contentType !== "undefined"
    ) {
      return new Promise((resolve) => {
        const promises = [];
        let parts = [];
        let quantityParts = 0;
        promises.push(store.setItem("file-" + this.indexFile, this.file));
        const partFiles = this.partFileString(this.base64File, this.contentType, this.file.name);
        quantityParts = partFiles.length;
        for (let i = 0; i < partFiles.length; i++) {
          let part = { IDPARTXX: i, PARTXXXX: this.contentType, status: false };
          promises.push(store.setItem("file-" + this.indexFile + "-part-" + i, partFiles[i].chunk));
          parts.push(part);
        }

        Promise.all(promises).then(() => {
          resolve({ quantityParts, parts, indexFile: this.indexFile });
        });
      });
    } else {
      throw new Error("File not Undefined");
    }
  }

  partFileString(string, type, fileName) {
    let partsFile = [];
    if (string.length > process.env.REACT_APP_GENIO_FILE_SIZE) {
      const numberOfChunks = Math.ceil(string.length / parseInt(process.env.REACT_APP_GENIO_FILE_SIZE));
      let start = 0;
      let end = parseInt(process.env.REACT_APP_GENIO_FILE_SIZE);
      for (var i = 0; i < numberOfChunks; i++) {
        let chunk = string.slice(start, end);
        chunk = chunk.split(",").length > 1 ? chunk.split(",")[1] : chunk;
        start = start + parseInt(process.env.REACT_APP_GENIO_FILE_SIZE);
        end = end + parseInt(process.env.REACT_APP_GENIO_FILE_SIZE);
        partsFile.push({ chunk, type: type, status: false, position: i, name: fileName });
      }
    } else {
      partsFile.push({ chunk: string, type: type, status: false, position: 0, name: fileName });
    }
    return partsFile;
  }

  removeFileByKey(key) {
    if (typeof key === "string") {
      return new Promise((resolve) => {
        store.removeItem(key).then(() => resolve(true));
      });
    }
  }

  removeFileByParts(index, quantityParts) {
    if (typeof index === "number" && typeof quantityParts === "number") {
      return new Promise((resolve) => {
        const promises = [];
        promises.push(this.removeFileStore(index));
        for (let i = 0; i < quantityParts; i++) {
          promises.push(this.removePartFileStore(index, i));
        }
        Promise.all(promises).then((response) => {
          resolve(true);
        });
      });
    } else {
      throw new Error("Index Undefined or Quantity Parts Undefined");
    }
  }

  removeFileStore(index) {
    if (typeof index === "number") {
      new Promise((resolve) => {
        store.removeItem("file-" + index).then(() => resolve(true));
      });
    } else {
      throw new Error("Index Undefined ");
    }
  }

  removePartFileStore(index, partNumber) {
    if (typeof index === "number" && typeof partNumber === "number") {
      new Promise((resolve) => {
        store.removeItem("file-" + index + "-part-" + partNumber).then(() => resolve(true));
      });
    } else {
      throw new Error("Index Undefined or Part Number Undefined ");
    }
  }

  findFileStore(index) {
    if (typeof index === "number") {
      return new Promise((resolve) => {
        store.getItem("file-" + index).then(() => resolve(true));
      });
    } else {
      throw new Error("Index Undefined ");
    }
  }

  findFileByKey(key) {
    if (typeof key === "string") {
      return new Promise((resolve) => {
        store.getItem(key).then((file) => resolve(file));
      });
    }
  }

  findPartFileStore(index, partNumber) {
    if (typeof index === "number" && typeof partNumber === "number") {
      return store.getItem("file-" + index + "-part-" + partNumber);
    } else {
      throw new Error("Index Undefined or Part Number Undefined ");
    }
  }

  deleteAll() {
    return new Promise((resolve) => {
      store.clear().then(() => resolve(true));
    });
  }
}
