import { apiSlice } from "../apiSlice";

export const asometxxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCompanyAreaSerieTypeMetadata: builder.mutation({
      query: (parameters) => ({
        url: `tipometadatos`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
  }),
});

export const { useCreateCompanyAreaSerieTypeMetadataMutation } = asometxxApiSlice;
