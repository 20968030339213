import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  useGetCompanysQuery,
  useLazyGetAreasByCompanyQuery,
  useLazyGetSeriesByAreaQuery,
  useLazyGetTypesBySerieQuery,
  useLazyGetMetadataByCompanyAreaSerieQuery,
} from "../../libs/redux/slices/filterSlice/filterApiSlice";
import Toast from "react-bootstrap/Toast";
import { useDispatch } from "react-redux";
import { useLazyGetOptionsMetadataQuery, useLazyGetMetadataQuery, useUpdateRelationMetadataMutation } from "../../libs/redux/slices/cremetxxSlice/cremetxxApiSlice";
import { useCreateCompanyAreaSerieTypeMetadataMutation } from "../../libs/redux/slices/asometxxSlice/asometxxApiSlice";
import { setFilters } from "../../libs/redux/slices/filterSlice/filterSlice";

const Asometxx = ({ onClose, row, isCreating, isEdit, setToastMessageP, setShowToastP }) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [company, setCompany] = useState("");
  const [area, setArea] = useState("");
  const [serie, setSerie] = useState("");
  const [type, setType] = useState("");
  const [metadataOptions, setMetadataOptions] = useState([]);
  const [metadataFields, setMetadataFields] = useState([{ METADATA: "", MANDATOR: "", ORDENXXX: "" }]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [reload, setReload] = useState(false);

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const [triggerOptionsMetadata, { isLoading: isLoadingOptionsMetadata }] = useLazyGetOptionsMetadataQuery();

  const [updateRelationMetadata, { isLoading: isLoadingUpdate }] = useUpdateRelationMetadataMutation();

  const [triggerMetadata] = useLazyGetMetadataQuery();

  const [insertCompanyAreaSerieTypeMetadata, { isLoading: isCompanyAreaSerieTypeMetadata }] =
    useCreateCompanyAreaSerieTypeMetadataMutation();

  const [findAreasByCompany, { data: areas, isLoading: isLoadingAreas, isUninitialized: isUninitializedAreas }] =
    useLazyGetAreasByCompanyQuery();
  const [findSeriesByArea, { data: series, isLoading: isLoadingSeries, isUninitialized: isUninitializedSeries }] =
    useLazyGetSeriesByAreaQuery();
  const [findTypesBySerie, { data: types, isLoading: isLoadingTypes, isUninitialized: isUninitializedTypes }] =
    useLazyGetTypesBySerieQuery();
  const [triggerCompanyMetadata,  { isLoading: isLoadingMetadata }] = useLazyGetMetadataByCompanyAreaSerieQuery();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      // Recorrer cada metadato, obligatoriedad y orden para insertarlos
      if (isCreating) {
        for (const field of metadataFields) {
          await insertCompanyAreaSerieTypeMetadata({
            EMPNITXX: company,
            AREAIDXX: area,
            SERIEIDX: serie,
            TIPOIDXX: type,
            METIDXXX: field.METADATA,
            BANOBLXX: field.MANDATOR,
            ORDENXXX: field.ORDENXXX,
          })
            .unwrap()
            .then(() => {
              onClose(false);
              setSavedSuccessfully(true);
            })
            .catch();
        }
      } else {
        await updateRelationMetadata({
          EMPNITXX: company,
          AREAIDXX: area,
          SERIEIDX: serie,
          TIPOIDXX: type,
          METIDXXX: metadataFields,
          MASIVEXX: true
        }).unwrap()
        .then(() => {
          onClose(false);
          setSavedSuccessfully(true);
        })
        .catch(() => {
          setReload((prevReload) => !prevReload);
        });
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    // Verificar si todas las variables tienen valor
    if (company && area && serie && type) {
      if (isCreating) {
        // EN caso de crear nuevos
        triggerOptionsMetadata({
          EMPNITXX: company,
          AREAIDXX: area,
          SERIEIDX: serie,
          TIPOIDXX: type,
        }).then((response) => {
          if (response?.data && response.data.data.count > 0) {
            setToastMessageP("El tipo documental ya cuenta con Metadatos asociados ingresar a EDITAR");
            setShowToastP(true);
            onClose(false);
          } else {
            triggerMetadata({
              fields: "fields=METIDXXX,METDESXX,REGUSRMX",
              filters: "filters[REGESTXX]=ACTIVO",
            }).then((response) => {
              if (response?.data) {
                const dataArray = Object.values(response.data);
                setMetadataOptions(
                  Array.from(new Set(dataArray[0].map(item => item.METIDXXX)))
                    .map(value => {
                      const area = dataArray[0].find(a => a.METIDXXX === value);
                      return { label: area.METDESXX, value: area.METIDXXX };
                    })
                );
              }
            })
            .catch(error => {
              console.error('Error al obtener datos:', error);
            });
          }
        })
        .catch(error => {
          onClose(false);
          console.error('Error al obtener datos:', error);
        });;
      } else {
        //En caso de editar/ver
        triggerMetadata({
          fields: "fields=METIDXXX,METDESXX,REGUSRMX",
          filters: "filters[REGESTXX]=ACTIVO",
        }).then((response) => {
          if (response?.data) {
            const dataArray = Object.values(response.data);
            setMetadataOptions(
              Array.from(new Set(dataArray[0].map(item => item.METIDXXX)))
                .map(value => {
                  const area = dataArray[0].find(a => a.METIDXXX === value);
                  return { label: area.METDESXX, value: area.METIDXXX };
                })
            );
          }
        })
        .catch(error => {
          console.error('Error al obtener datos:', error);
        });
      }

    }
    // eslint-disable-next-line
  }, [type, company, area, serie, row, triggerOptionsMetadata, triggerMetadata, isCreating]);

  // Función para agregar una nueva fila de Metadato y Obligatoriedad
  const addMetadataField = () => {
    setMetadataFields([...metadataFields, { METADATA: "", MANDATOR: "", ORDENXXX: "" }]);
  };

  // Función para eliminar una fila específica de Metadato, Obligatoriedad y Orden
  const removeMetadataField = (index) => {
    if (metadataFields.length > 1) {
      setMetadataFields(metadataFields.filter((_, i) => i !== index));
    }
  };

  const validateMetadataChange = (index, field, value) =>{
    // Validar si el valor de ORDENXXX ya existe en otro campo
    const alreadyExists = metadataFields.some((item, idx) => idx !== index && item[field] === value);
    if (alreadyExists) {
      setToastMessage("El valor de " + field + " debe ser único.");
      setShowToast(true);
      const newMetadataFields = [...metadataFields];
      newMetadataFields[index][field] = "";
      setMetadataFields(newMetadataFields);
      return;
    }
  };

  // Maneja los cambios en los campos de Metadato, Obligatoriedad y Orden
  const handleMetadataChange = (index, field, value) => {
    const newMetadataFields = [...metadataFields];
    newMetadataFields[index][field] = value;
    setMetadataFields(newMetadataFields);
  };

  useEffect(() => {
    if (company !== "") {
      findAreasByCompany({ EMPNITXX: company, AREAIDXX: "null" });
    }
  }, [company, findAreasByCompany]);

  useEffect(() => {
    if (company !== "" && area !== "") {
      findSeriesByArea({ EMPNITXX: company, AREAIDXX: area, SERIEIDX: "null" });
    }
  }, [company, area, findSeriesByArea]);

  useEffect(() => {
    if (company !== "" && area !== "" && serie !== "") {
      findTypesBySerie({
        EMPNITXX: company,
        AREAIDXX: area,
        SERIEIDX: serie,
        TIPOIDXX: "null",
      });
    }
  }, [company, area, serie, findTypesBySerie]);

  useEffect(() => {
    if (!isLoadingCompanys && row?.EMPNITXX) {
      setCompany(row.EMPNITXX);
    }
  }, [isLoadingCompanys, row]);

  useEffect(() => {
    if (!isLoadingCompanys && !isLoadingAreas && row?.AREAIDXX) {
      setArea(row.AREAIDXX);
    }
  }, [isLoadingCompanys, isLoadingAreas, row]);

  useEffect(() => {
    if (!isLoadingCompanys && !isLoadingAreas && !isLoadingSeries && row?.SERIEIDX) {
      setSerie(row.SERIEIDX);
    }
  }, [isLoadingCompanys, isLoadingAreas, isLoadingSeries, row]);

  useEffect(() => {
    if (!isLoadingCompanys && !isLoadingAreas && !isLoadingSeries && !isLoadingTypes && row?.TIPOIDXX) {
      setType(row.TIPOIDXX);
    }
  }, [isLoadingCompanys, isLoadingAreas, isLoadingSeries, isLoadingTypes, row]);

  useEffect(() => {
    if (company && area && serie && type && !isCreating) {
      // Llamada a la API
      triggerCompanyMetadata({
        EMPNITXX: company,
        AREAIDXX: area,
        SERIEIDX: serie,
        TIPOIDXX: type,
        METIDXXX: "null",
      }).then((response) => {
        if (response?.data) {
          const dataArray = Object.values(response.data);
          setMetadataFields(
            Array.from(new Set(dataArray[0].filter(item => item.REGESTXX === 'ACTIVO').map(item => item.METIDXXX)))
              .map(value => {
                const area = dataArray[0].find(a => a.METIDXXX === value && a.REGESTXX === 'ACTIVO');
                return { METADATA: area.METIDXXX, MANDATOR: area.BANOBLXX, ORDENXXX: String(area.ORDENXXX) };
              })
          );
        }
      }).catch((error) => {
        console.error('Error al obtener metadatos:', error);
      });
    }
  }, [company, area, serie, type, row, isCreating, triggerCompanyMetadata, reload]);

  const handleFilter = () => {
    let filters = "filters[REGESTXX]=ACTIVO";
    dispatch(setFilters(filters));
  };

  useEffect(() => {
    if (savedSuccessfully) {
      handleFilter();
      setSavedSuccessfully(false);
    }
    // eslint-disable-next-line
  }, [savedSuccessfully]);

  return isCompanyAreaSerieTypeMetadata || isLoadingCompanys || isLoadingOptionsMetadata ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Empresa:*</Form.Label>
          <Form.Select
            value={company}
            required
            disabled={!isCreating}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          >
            {isLoadingCompanys ? (
              <option value={""}>CARGANDO...</option>
            ) : (
              companys?.data?.map((company, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={company.EMPNITXX} value={company.EMPNITXX}>
                      {company.EMPDESXX}
                    </option>
                  </>
                ) : (
                  <option key={company.EMPNITXX} value={company.EMPNITXX}>
                    {company.EMPDESXX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Empresa</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <Form.Label>Proceso:*</Form.Label>
          <Form.Select
            onChange={(e) => {
              setArea(e.target.value);
            }}
            required
            disabled={!isCreating}
            value={area}
          >
            {isLoadingAreas || isUninitializedAreas ? (
              <option value={""}>[SELECCIONE]</option>
            ) : (
              areas?.data?.map((area, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                      {area.AREADESX}
                    </option>
                  </>
                ) : (
                  <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                    {area.AREADESX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un Proceso</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <Form.Label>Serie:*</Form.Label>
          <Form.Select
            onChange={(e) => {
              setSerie(e.target.value);
            }}
            required
            disabled={!isCreating}
            value={serie}
          >
            {isLoadingSeries || isUninitializedSeries ? (
              <option value={""}>[SELECCIONE]</option>
            ) : (
              series?.data?.map((serie, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                      {serie.SERIEDES}
                    </option>
                  </>
                ) : (
                  <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                    {serie.SERIEDES}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Serie</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Tipo Documental:*</Form.Label>
          <Form.Select
            onChange={(e) => {
              setType(e.target.value);
            }}
            required
            disabled={!isCreating}
            value={type}
          >
            {isLoadingTypes || isUninitializedTypes ? (
              <option value={""}>[SELECCIONE]</option>
            ) : (
              types?.data?.map((types, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option
                      key={`${types.EMPNITXX}-${types.AREAIDXX}-${types.SERIEIDX}-${types.TIPOIDXX}`}
                      value={types.TIPOIDXX}
                    >
                      {types.TIPODESX}
                    </option>
                  </>
                ) : (
                  <option
                    key={`${types.EMPNITXX}-${types.AREAIDXX}-${types.SERIEIDX}-${types.TIPOIDXX}`}
                    value={types.TIPOIDXX}
                  >
                    {types.TIPODESX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar un Tipo Documental</Form.Control.Feedback>
        </Form.Group>
        {isLoadingMetadata ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          metadataFields.map((field, index) => (
            <Row key={index} md={12} className="mt-3">
              <Form.Group as={Col} md={5}>
                <Form.Label>Metadato:*</Form.Label>
                <Form.Select
                  value={field.METADATA}
                  required
                  disabled={!isEdit && !isCreating}
                  onChange={(e) => {
                    handleMetadataChange(index, "METADATA", e.target.value);
                    validateMetadataChange(index, "METADATA", e.target.value);
                  }}
                >
                  <option value={""}>[SELECCIONE]</option>
                  {Array.isArray(metadataOptions) && metadataOptions.length > 0 &&
                    metadataOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">Debe Seleccionar un Metadato</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={3}>
                <Form.Label>Obligatoriedad*</Form.Label>
                <Form.Select
                  value={field.MANDATOR}
                  required
                  disabled={!isEdit && !isCreating}
                  onChange={(e) => handleMetadataChange(index, "MANDATOR", e.target.value)}
                >
                  <option value={""}>[SELECCIONE]</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">Debe Seleccionar la Obligatoriedad</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={2}>
                <Form.Label>Orden:*</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  required
                  value={field.ORDENXXX}
                  disabled={!isEdit && !isCreating}
                  onChange={(e) => handleMetadataChange(index, "ORDENXXX", e.target.value)}
                  onBlur={(e) => validateMetadataChange(index, "ORDENXXX", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Debe ingresar un número único y válido</Form.Control.Feedback>
              </Form.Group>
              <Col md={2} className="d-flex align-items-center">
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ms-2 rounded-circle"
                  onClick={addMetadataField}
                  disabled={!isEdit && !isCreating}
                  style={{ width: "30px", height: "30px", color: "white", backgroundColor: "#007bff", border: "none" }}
                >
                  +
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="ms-2 rounded-circle bg-secondary text-white"
                  onClick={() => removeMetadataField(index)}
                  disabled={metadataFields.length === 1 ||  (!isEdit && !isCreating)}
                  style={{ width: "30px", height: "30px", border: "none" }}
                >
                  ×
                </Button>
              </Col>
            </Row>
          ))
        )}
      </Row>
        {(isEdit || isCreating) && (
        <Row md={12} className="mt-3">
          <Col className="offset-10" md={1}>
            <Button type="Submit">Guardar</Button>
          </Col>
          {isCreating && (
          <Col md={1}>
            <Button
              onClick={(e) => {
                setValidated(false);
                setCompany("");
                setArea("");
                setSerie("");
                setType("");
                setMetadataFields([{ METADATA: "", MANDATOR: "", ORDENXXX: "" }]); // Reiniciar los campos dinámicos
              }}
              disabled={isLoadingUpdate || isCompanyAreaSerieTypeMetadata}
            >
              Limpiar
            </Button>
          </Col>
          )}
        </Row>
        )}
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 9999,
          }}
        >
          <Toast.Header>
            <strong className="me-auto">Notificación</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
    </Form>
  );
};

export default Asometxx;
