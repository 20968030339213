import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  useGetCompanysQuery,
  useLazyGetAreasByCompanyQuery,
  useLazyGetSeriesByAreaQuery,
  useLazyGetTypesBySerieQuery,
  useLazyGetMetadataByCompanyAreaSerieQuery,
} from "../../libs/redux/slices/filterSlice/filterApiSlice";
import {
  setCompanySelected,
  setAreaSelected,
  setSerieSelected,
  setTypeSelected,
  setMetadataSelected,
  setFilters,
  resetValues,
  getCompanySelected,
  getAreaSelected,
  getSerieSelected,
  getTypeSelected,
  getMetadataSelected,
  createFiltersBy,
  setCargoSelected,
  setUserSelected,
  setPermisoSelected,
  getPermisoSelected,
} from "../../libs/redux/slices/filterSlice/filterSlice";
import { useLazyGetCargosQuery } from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";
import { useLazyGetUserQuery } from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice } from "../../libs/redux/slices/apiSlice";
import { useLocation } from "react-router-dom";
import { useState } from "react";
const Filtersx = () => {
  const companySelected = useSelector(getCompanySelected);
  const areaSelected = useSelector(getAreaSelected);
  const serieSelected = useSelector(getSerieSelected);
  const typeSelected = useSelector(getTypeSelected);
  const metadataSelected = useSelector(getMetadataSelected);
  const filters = useSelector(createFiltersBy);
  const permisoSelect = useSelector(getPermisoSelected);
  const dispatch = useDispatch();
  const dispatchCompany = useDispatch();
  const dispatchArea = useDispatch();
  const dispatchSerie = useDispatch();
  const dispatchType = useDispatch();
  const dispatchMetadata = useDispatch();
  const dispatchCargo = useDispatch();
  const dispatchUser = useDispatch();
  const dispatchPermiso = useDispatch();
  const dispatchReset = useDispatch();
  const typeaheadRefUser = useRef(null);
  const typeaheadRefCargo = useRef(null);
  const [options, setOptions] = useState([]);
  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();

  const [findAreasByCompany, { data: areas, isLoading: isLoadingAreas, isUninitialized: isUninitializedAreas }] =
    useLazyGetAreasByCompanyQuery();

  const [findSeriesByArea, { data: series, isLoading: isLoadingSeries, isUninitialized: isUninitializedSeries }] =
    useLazyGetSeriesByAreaQuery();

  const [findTypesBySerie, { data: types, isLoading: isLoadingTypes, isUninitialized: isUninitializedTypes }] =
    useLazyGetTypesBySerieQuery();

  const [
    findMetadataByCompanyAreaSerie,
    { data: metadata, isLoading: isLoadingMetadata, isUninitialized: isUninitializedMetadata },
  ] = useLazyGetMetadataByCompanyAreaSerieQuery();
  const [findCargos, {isLoading : loadcargos}] = useLazyGetCargosQuery();
  const [findUser, {isLoading : loaduser}] = useLazyGetUserQuery();
  let location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (companySelected !== "") {
      findAreasByCompany({ EMPNITXX: companySelected, AREAIDXX: "null" });
    }
  }, [companySelected, findAreasByCompany]);

  useEffect(() => {
    if (companySelected !== "" && areaSelected !== "") {
      findSeriesByArea({ EMPNITXX: companySelected, AREAIDXX: areaSelected, SERIEIDX: "null" });
    }
  }, [areaSelected, companySelected, findSeriesByArea]);

  useEffect(() => {
    if (companySelected !== "" && areaSelected !== "" && serieSelected !== "") {
      findTypesBySerie({
        EMPNITXX: companySelected,
        AREAIDXX: areaSelected,
        SERIEIDX: serieSelected,
        TIPOIDXX: "null",
      });
    }
  }, [companySelected, areaSelected, serieSelected, findTypesBySerie]);

  useEffect(() => {
    if (companySelected !== "" && areaSelected !== "" && serieSelected !== "" && typeSelected !== "") {
      findMetadataByCompanyAreaSerie({
        EMPNITXX: companySelected,
        AREAIDXX: areaSelected,
        SERIEIDX: serieSelected,
        TIPOIDXX: typeSelected,
        METIDXXX: "null",
      });
    }
  }, [companySelected, areaSelected, serieSelected, typeSelected, findMetadataByCompanyAreaSerie]);

  const serachCarg = async (value) => {
    if (value !== "") {
      try {
        const response = await findCargos(value);
        let form = [];
        if (response?.data) {
          form = response.data.map((cargos) => {
            return { id: cargos.CARIDXXX, label: cargos.CARDESXX };
          });
        }
        setOptions(form);
      } catch (error) {
        console.error("Error fetching cargos:", error);
      }
    }
  };
  const searchUser = async (value) => {
    if (value !== "") {
      try {
        const response = await findUser(value);
        let form = [];
        if (response?.data) {
          form = response.data.map((usuario) => {
            return { id: usuario.USRIDXXX, label: usuario.USRNOMXX };
          });
        }
        setOptions(form);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
  };
  function f_CleanSearch() {
    typeaheadRefUser.current && typeaheadRefUser.current.clear();
    typeaheadRefCargo.current && typeaheadRefCargo.current.clear();
  }
  return (
    <Container>
      <Form className="mt-4">
        <Row md={12}>
          <Form.Group as={Col} md={4}>
            <Form.Label>Empresa:</Form.Label>
            <Form.Select
              onChange={(e) => {
                dispatchCompany(setCompanySelected(e.target.value));
              }}
              value={companySelected}
            >
              {isLoadingCompanys ? (
                <option value={""}>CARGANDO...</option>
              ) : (
                companys?.data?.map((company, index) => {
                  return index === 0 ? (
                    <>
                      <option value={""}>[SELECCIONE]</option>
                      <option key={company.EMPNITXX} value={company.EMPNITXX}>
                        {company.EMPDESXX}
                      </option>
                    </>
                  ) : (
                    <option key={company.EMPNITXX} value={company.EMPNITXX}>
                      {company.EMPDESXX}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          {
            path === "/percargo" && (
              <Form.Group as={Col} md={4}>
                <Form.Label>Cargo:</Form.Label>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="cSearchCargo"
                  isLoading={loadcargos}
                  minLength={3}
                  onSearch={serachCarg}
                  options={options}
                  placeholder="Busqueda por Cargo"
                  renderMenuItemChildren = {(options) => (
                    <span>{options.id} - {options.label}</span>
                  )}
                  ref={typeaheadRefCargo}
                  onChange={(select) => {
                    if (select.length > 0) {
                      dispatchCargo(setCargoSelected(select[0].id))
                    }
                  }}
                />
              </Form.Group>
            )
          }
          {
            path === "/peruser" && (
              <Form.Group as={Col} md={4}>
                <Form.Label>Usuario:</Form.Label>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="cSearchUser"
                  isLoading={loaduser}
                  minLength={3}
                  onSearch={searchUser}
                  options={options}
                  placeholder="Busqueda Por Usuario"
                  renderMenuItemChildren = {(options) => (
                    <span>{options.id} - {options.label}</span>
                  )}
                  ref={typeaheadRefUser}
                  onChange={(select) => {
                    if (select.length > 0) {
                      dispatchUser(setUserSelected(select[0].id))
                    }
                  }}
                />
              </Form.Group>
            )
          }
          <Form.Group as={Col} md={4}>
            <Form.Label>Proceso:</Form.Label>
            <Form.Select
              onChange={(e) => {
                dispatchArea(setAreaSelected(e.target.value));
              }}
              value={areaSelected}
            >
              {isLoadingAreas || isUninitializedAreas ? (
                <option value={""}>[SELECCIONE]</option>
              ) : (
                areas?.data?.map((area, index) => {
                  return index === 0 ? (
                    <>
                      <option value={""}>[SELECCIONE]</option>
                      <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                        {area.AREADESX}
                      </option>
                    </>
                  ) : (
                    <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                      {area.AREADESX}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Serie:</Form.Label>
            <Form.Select
              onChange={(e) => {
                dispatchSerie(setSerieSelected(e.target.value));
              }}
              value={serieSelected}
            >
              {isLoadingSeries || isUninitializedSeries ? (
                <option value={""}>[SELECCIONE]</option>
              ) : (
                series?.data?.map((serie, index) => {
                  return index === 0 ? (
                    <>
                      <option value={""}>[SELECCIONE]</option>
                      <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                        {serie.SERIEDES}
                      </option>
                    </>
                  ) : (
                    <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                      {serie.SERIEDES}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Tipo Documental:</Form.Label>
            <Form.Select
              onChange={(e) => {
                dispatchType(setTypeSelected(e.target.value));
              }}
              value={typeSelected}
            >
              {isLoadingTypes || isUninitializedTypes ? (
                <option value={""}>[SELECCIONE]</option>
              ) : (
                types?.data?.map((types, index) => {
                  return index === 0 ? (
                    <>
                      <option value={""}>[SELECCIONE]</option>
                      <option
                        key={`${types.EMPNITXX}-${types.AREAIDXX}-${types.SERIEIDX}-${types.TIPOIDXX}`}
                        value={types.TIPOIDXX}
                      >
                        {types.TIPODESX}
                      </option>
                    </>
                  ) : (
                    <option
                      key={`${types.EMPNITXX}-${types.AREAIDXX}-${types.SERIEIDX}-${types.TIPOIDXX}`}
                      value={types.TIPOIDXX}
                    >
                      {types.TIPODESX}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          {
            path === "/admin" && (
              <Form.Group as={Col} md={4}>
              <Form.Label>Metadato:</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    dispatchMetadata(setMetadataSelected(e.target.value));
                  }}
                  value={metadataSelected}
                >
                  {isLoadingMetadata || isUninitializedMetadata ? (
                    <option value={""}>[SELECCIONE]</option>
                  ) : (
                    metadata?.data?.map((metadata, index) => {
                      return index === 0 ? (
                        <>
                          <option value={""}>[SELECCIONE]</option>
                          <option
                            key={`${metadata.EMPNITXX}-${metadata.AREAIDXX}-${metadata.SERIEIDX}-${metadata.TIPOIDXX}-${metadata.METIDXXX}`}
                            value={metadata.METIDXXX}
                          >
                            {metadata.METDESXX}
                          </option>
                        </>
                      ) : (
                        <option
                          key={`${metadata.EMPNITXX}-${metadata.AREAIDXX}-${metadata.SERIEIDX}-${metadata.TIPOIDXX}-${metadata.METIDXXX}`}
                          value={metadata.METIDXXX}
                        >
                          {metadata.METDESXX}
                        </option>
                      );
                    })
                  )}
                </Form.Select>
              </Form.Group>
            )
          }
          {
            path === "/percargo" && (
              <Form.Group as={Col} md={4}>
                <Form.Label>Permiso:</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    dispatchPermiso(setPermisoSelected(e.target.value))
                  }}
                  value={permisoSelect}
                >
                  <option value={""}>[SELECCIONE]</option>
                  <option value={"filters[CARGUEXX]=SI"}>CARGAR</option>
                  <option value={"filters[VERXXXXX]=SI"}>VISUALIZAR</option>
                  <option value={"filters[EDITXXXX]=SI"}>EDITAR</option>
                  <option value={"filters[DELETEXX]=SI"}>ELIMINAR</option>
                </Form.Select>
              </Form.Group>
            )
          }
          {
            path === "/peruser" && (
              <Form.Group as={Col} md={4}>
                <Form.Label>Permiso:</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    dispatchPermiso(setPermisoSelected(e.target.value))
                  }}
                  value={permisoSelect}
                >
                  <option value={""}>[SELECCIONE]</option>
                  <option value={"filters[CARGUEXX]=SI"}>CARGUE</option>
                  <option value={"filters[VERXXXXX]=SI"}>VISUALIZAR</option>
                  <option value={"filters[EDITXXXX]=SI"}>EDITAR</option>
                  <option value={"filters[DELETEXX]=SI"}>ELIMINAR</option>
                </Form.Select>
              </Form.Group>
            )
          }

        </Row>
        <Row md={12} className="mt-4">
          <Col md={2} className="offset-8">
            <Button
              className="w-100"
              onClick={() => {console.log(filters);
                if (filters.length > 0) {
                  dispatch(setFilters(filters));
                }
              }}
            >
              FILTRAR
            </Button>
          </Col>
          <Col md={2}>
            <Button
              className="w-100"
              onClick={() => {
                dispatchReset(resetValues());
                f_CleanSearch();
                dispatch(apiSlice.util.invalidateTags(["reloadTable"]));
              }}
            >
              LIMPIAR
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Filtersx;
