import React, { useEffect } from "react";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import { setShowMessage, getShowConfirm, getMessage } from "../../libs/redux/slices/confirmxxSlice/confirmxxSlice";
import { useSelector, useDispatch } from "react-redux";

const Confirxx = () => {
  const dispatch = useDispatch();
  const message = useSelector(getMessage);
  const showToast = useSelector(getShowConfirm);

  useEffect(() => {
    if (!showToast) {
      setTimeout(() => {
        dispatch(setShowMessage({ message: "" }));
      }, 5000);
    }
  }, [showToast, dispatch]);
  return (
    <ToastContainer className="p-3" position="top-end">
      <Toast onClose={() => dispatch(setShowMessage({ message: "" }))} show={showToast} delay={5000} autohide>
        <Toast.Header bg="error">
          <strong className="me-auto">Confirmacion</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Confirxx;
