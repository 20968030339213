import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";

import {
  useCreateTypeMutation,
  useCreateCompanyAreaSerieTypeMutation,
  useLazyGetTypeParentQuery,
} from "../../libs/redux/slices/cretipxxSlice/cretipxxApiSlice";

import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch } from "react-redux";
import Reutilxx from "./Reutilxx";

const Cretipxx = ({ onClose }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const [insertType, { isLoading: isLoadingType }] = useCreateTypeMutation();
  const [insertCompanyAreaSerieType, { isLoading: isLoadingCompanyAreaSerieType }] =
    useCreateCompanyAreaSerieTypeMutation();

  const [validated, setValidated] = useState(false);
  const [typeDescription, setTypeDescription] = useState("");
  const [company, setCompany] = useState("");
  const [area, setArea] = useState("");
  const [serie, setSerie] = useState("");
  const [descriptionPath, setDescriptionPath] = useState("");
  const [homologation, setHomologation] = useState("");
  const [documentParent, setDocumentParent] = useState(false);
  const [companyParent, setCompanyParent] = useState("");
  const [areaParent, setAreaParent] = useState("");
  const [serieParent, setSerieParent] = useState("");
  const [typeParent, setTypeParent] = useState("");
  const [getTypeParent, { data: typesParent, isSuccess: isLoadingTypes }] = useLazyGetTypeParentQuery();
  const [dataTypes, setDataTypes] = useState([]);

  //Hook para setear los tipos padres
  useEffect(() => {
    if (isLoadingTypes) {
      const { data } = typesParent;
      setDataTypes(data);
    }
  }, [isLoadingTypes, typesParent]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() && company !== "" && area !== "") {
      await insertType({
        TIPODESX: typeDescription,
        TIPOPATH: descriptionPath,
      })
        .unwrap()
        .then((response) => {
          saveCompanyAreaSerieType(response.data.TIPOIDXX);
        })
        .catch();
    } else {
      event.preventDefault();
    }
    setValidated(true);
  };

  const saveCompanyAreaSerieType = async (typeID) => {
    await insertCompanyAreaSerieType({
      EMPNITXX: company,
      AREAIDXX: area,
      SERIEIDX: serie,
      TIPOIDXX: typeID,
      HOMDIAXX: homologation,
      ISPARENT: documentParent ? "SI" : "NO",
      EMPNITPA: companyParent,
      AREAIDPA: areaParent,
      SERIEIPA: serieParent,
      TIPOIDPA: typeParent,
    })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };

  //Funcion para consultar tipos
  const handleChangeSerie = (value) => {
    setSerieParent(value);
    getTypeParent({ EMPNITXX: companyParent, AREAIDXX: areaParent, SERIEIDX: value });
  };

  //Control check tiene documento padre
  const handleIsDocumentParent = (checked) => {
    setDocumentParent(checked);
    setCompanyParent("");
    setAreaParent("");
    setSerieParent("");
    setTypeParent("");
  };

  return isLoadingType || isLoadingCompanyAreaSerieType ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Tipo*</Form.Label>
          <InputGroup>
            <Form.Control
              value={typeDescription}
              type="text"
              required
              onChange={(e) => {
                setTypeDescription(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre del Tipo</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => {
                setDescriptionPath(e.target.value);
              }}
              minLength={8}
              maxLength={8}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Reutilxx
          company={company}
          handleChangeCompany={setCompany}
          area={area}
          handleChangeArea={setArea}
          serie={serie}
          handleChangeSerie={setSerie}
          documentParent={documentParent}
        />
        <Row md={12} className="mt-3">
          <Form.Group as={Col} md={4}>
            <Form.Label>Homologación DIAN</Form.Label>
            <Form.Control
              type="text"
              value={homologation}
              onChange={(e) => {
                setHomologation(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>
              <br />
            </Form.Label>
            <Form.Check
              label="Tiene documento Padre"
              type="checkbox"
              checked={documentParent}
              onChange={(e) => handleIsDocumentParent(e.target.checked)}
            />
          </Form.Group>
          {documentParent && (
            <>
              <Reutilxx
                company={companyParent}
                handleChangeCompany={setCompanyParent}
                area={areaParent}
                handleChangeArea={setAreaParent}
                serie={serieParent}
                handleChangeSerie={handleChangeSerie}
                documentParent={documentParent}
              />
              <Form.Group as={Col} md={4}>
                <Form.Label>Tipo Documental Padre:*</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setTypeParent(e.target.value);
                  }}
                  value={typeParent}
                  required
                >
                  <option value={""}>[SELECCIONE]</option>
                  {dataTypes.length > 0 && (
                    <>
                      {dataTypes.map((type) => (
                        <option key={`${type.TIPOIDXX}`} value={type.TIPOIDXX}>
                          {type.TIPODESX}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">Debe Seleccionar un Tipo Documental Padre</Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Row>
        <Row md={12} className="mt-3">
          <Col className="offset-10" md={1}>
            <Button type="Submit">Guardar</Button>
          </Col>
          <Col md={1}>
            <Button
              onClick={(e) => {
                setValidated(false);
                setTypeDescription("");
                setDescriptionPath("");
                setCompany("");
                setArea("");
                setSerie("");
                handleIsDocumentParent(false);
                setDocumentParent(false);
              }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </Row>
    </Form>
  );
};

export default Cretipxx;
