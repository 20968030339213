import { ProgressBar } from "react-bootstrap";

export const columnsTransform = (columns, onHandClickDelete) => {
  const columnsFormat = columns.map((item, index) => {
    let column = {
      id: item.id,
      name: item.title,
      selector: (row) => {
        switch (item.title) {
          case "Porcentaje":
            return (
              <>
                <ProgressBar variant="success" now={row.PORCENXX} style={{ width: "200px" }} />
                {row.PORCENXX}%
              </>
            );
          default:
            return row[item.position];
        }
      },
      width: item.width,
    };
    return column;
  });
  columnsFormat.push({
    name: "Acciones",
    cell: (row, index) => {
      return (
        <>
          <span
            style={{ cursor: "pointer", fontSize: "29px" }}
            className="bi bi-trash"
            onClick={() => onHandClickDelete(row, index)}
          />
          {row.INTENTOS >= 4  ? (
            <span
              style={{ cursor: "pointer", fontSize: "29px" }}
              className="bi bi-x-circle"
              onClick={() => onHandClickDelete(row, index)}
            />
          ) : (
            row.INPROCES ? (
              <span style={{ cursor: "pointer", fontSize: "29px" }} className="bi bi-cloud-upload" />
            ) : ""
          )}
        </>
      );
    },
  });

  return columnsFormat;
};
