import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Modal, Spinner } from "react-bootstrap";
import { columnsTracking, buttons } from "./configxx";
import Tablexxx from "../Tablexxx";
import Asometxx from "../../components/Asometxx";
import { useGetCompanysQuery, useLazyGetAreasByCompanyQuery, useLazyGetSeriesByAreaQuery, useLazyGetTypesBySerieQuery, useLazyGetMetadataByCompanyAreaSerieQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import { useDispatch } from "react-redux";
import { setFilters } from "../../libs/redux/slices/filterSlice/filterSlice";
import Toast from "react-bootstrap/Toast";


const Tabsproc = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [component, setComponent] = useState("");
  const [row, setRow] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { data: companies, isLoading: isLoadingCompanies } = useGetCompanysQuery();
  const [triggerCompanyProcess, { isLoading: isLoadingCompanyProcess }] = useLazyGetAreasByCompanyQuery();
  const [triggerCompanySerie, { isLoading: isLoadingCompanySerie }] = useLazyGetSeriesByAreaQuery();
  const [triggerCompanyType, { isLoading: isLoadingCompanyType }] = useLazyGetTypesBySerieQuery();
  const [triggerCompanyMetadata, { isLoading: isLoadingCompanyMetadata }] = useLazyGetMetadataByCompanyAreaSerieQuery();

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedProcess, setSelectedProcess] = useState("");
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [selectedSerie, setSelectedSerie] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedMetadata, setSelectedMetadata] = useState("");
  const [areasOptions, setAreasOptions] = useState([]);
  const [seriesOptions, setSeriesOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [metadataOptions, setMetadataOptions] = useState([]);

  useEffect(() => {
    if (companies?.data) {
      setCompaniesOptions(
        Array.from(new Set(companies.data.map(company => company.EMPNITXX)))
          .map(value => {
            const company = companies.data.find(c => c.EMPNITXX === value);
            return { label: company.EMPNOMXX, value: company.EMPNITXX };
          })
      );
    }
  }, [companies]);


  useEffect(() => {
    console.log(showToast);
  }, [showToast]);

  useEffect(() => {
    const EMPNITXX = selectedCompany || "null";
    const AREAIDXX = selectedProcess || "null";
    const SERIEIDX = selectedSerie || "null";
    const TIPOIDXX = selectedType || "null";
    const METIDXXX = selectedMetadata || "null";

    if (selectedCompany && !selectedProcess && !selectedSerie && !selectedType && !selectedMetadata) {
      triggerCompanyProcess({ EMPNITXX, AREAIDXX })
        .then((response) => {
          if (response?.data) {
            const dataArray = Object.values(response.data);
            setAreasOptions(
              Array.from(new Set(dataArray[0].map(item => item.AREAIDXX)))
                .map(value => {
                  const area = dataArray[0].find(a => a.AREAIDXX === value);
                  return { label: area.AREADESX, value: area.AREAIDXX };
                })
            );
          }
        })
        .catch(error => {
          console.error('Error al obtener datos:', error);
        });
    }

    if (selectedProcess && !selectedSerie && !selectedType && !selectedMetadata) {
      triggerCompanySerie({ EMPNITXX, AREAIDXX, SERIEIDX })
        .then((response) => {
          if (response?.data) {
            const dataArray = Object.values(response.data);
            setSeriesOptions(
              Array.from(new Set(dataArray[0].map(item => item.SERIEIDX)))
                .map(value => {
                  const area = dataArray[0].find(a => a.SERIEIDX === value);
                  return { label: area.SERIEDES, value: area.SERIEIDX };
                })
            );
          }
        })
        .catch(error => {
          console.error('Error al obtener datos:', error);
        });
    }
    if (selectedSerie && !selectedType && !selectedMetadata) {
      triggerCompanyType({ EMPNITXX, AREAIDXX, SERIEIDX, TIPOIDXX })
        .then((response) => {
          if (response?.data) {
            const dataArray = Object.values(response.data);
            setTypeOptions(
              Array.from(new Set(dataArray[0].map(item => item.TIPOIDXX)))
                .map(value => {
                  const area = dataArray[0].find(a => a.TIPOIDXX === value);
                  return { label: area.TIPODESX, value: area.TIPOIDXX };
                })
            );
          }
        })
        .catch(error => {
          console.error('Error al obtener datos:', error);
        });
    }

    if (selectedType && !selectedMetadata) {
      triggerCompanyMetadata({ EMPNITXX, AREAIDXX, SERIEIDX, TIPOIDXX, METIDXXX })
        .then((response) => {
          if (response?.data) {
            const dataArray = Object.values(response.data);
            setMetadataOptions(
              Array.from(new Set(dataArray[0].map(item => item.METIDXXX)))
                .map(value => {
                  const area = dataArray[0].find(a => a.METIDXXX === value);
                  return { label: area.METDESXX, value: area.METIDXXX };
                })
            );
          }
        })
        .catch(error => {
          console.error('Error al obtener datos:', error);
        });
    }
  },
  [ selectedCompany,
    selectedProcess,
    selectedSerie,
    selectedType,
    selectedMetadata,
    triggerCompanyProcess,
    triggerCompanyType,
    triggerCompanyMetadata,
    triggerCompanySerie]);

  const handleFilter = () => {
    let filters = "filters[REGESTXX]=ACTIVO";
    if (selectedCompany) {
      filters += `&filters[EMPNITXX]=${selectedCompany}`;
    }
    if (selectedProcess) {
      filters += `&filters[AREAIDXX]=${selectedProcess}`;
    }
    if (selectedSerie) {
      filters += `&filters[SERIEIDX]=${selectedSerie}`;
    }
    if (selectedType) {
      filters += `&filters[TIPOIDXX]=${selectedType}`;
    }
    if (selectedMetadata) {
      filters += `&filters[METIDXXX]=${selectedMetadata}`;
    }
    dispatch(setFilters(filters));
  };

  const handleClear = () => {
    // Blanqueo Filtros
    setSelectedCompany("");
    setSelectedProcess("");
    setSelectedSerie("");
    setSelectedType("");
    setSelectedMetadata("");
    dispatch(setFilters(""));
    // Blanqueo Opciones
    setAreasOptions([]);
    setSeriesOptions([]);
    setTypeOptions([]);
    setMetadataOptions([]);
  };

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    // Blanqueo Filtros
    setSelectedProcess("");
    setSelectedSerie("");
    setSelectedType("");
    setSelectedMetadata("");

    // Blanqueo opciones
    setAreasOptions([]);
    setSeriesOptions([]);
    setTypeOptions([]);
    setMetadataOptions([]);
  };

  const handleAreaChange = (e) => {
    setSelectedProcess(e.target.value);
    // Blanqueo Filtros
    setSelectedSerie("");
    setSelectedType("");
    setSelectedMetadata("");

    // Blanqueo opciones
    setSeriesOptions([]);
    setTypeOptions([]);
    setMetadataOptions([]);
  };

  const handleSerieChange = (e) => {
    setSelectedSerie(e.target.value);
    // Blanqueo Filtros
    setSelectedType("");
    setSelectedMetadata("");

    // Blanqueo Opciones
    setTypeOptions([]);
    setMetadataOptions([]);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    // Blanqueo Filtros
    setSelectedMetadata("");

    // Blanqueo Opciones
    setMetadataOptions([]);
  };

  const handleMetadataChange = (e) => {
    setSelectedMetadata(e.target.value);
  };



  let oComponent;
  let tittle;
  switch (component) {
    case "EDITAR":
      oComponent = <Asometxx onClose={setShow} row={row} isCreating={false} isEdit={true} />;
      tittle = "EDITAR ASOCIAR METADATO";
    break;
    case "VISUALIZAR":
      oComponent = <Asometxx onClose={setShow} row={row} isCreating={false} isEdit={false} />;
      tittle = "VER ASOCIAR METADATO";
    break;
    case "CREAR":
      oComponent = <Asometxx onClose={setShow} row={row} isCreating={true} isEdit={false} setToastMessageP={setToastMessage} setShowToastP={setShowToast}  />;
      tittle = "CREAR ASOCIAR METADATO";
      break;
    default:
      break;
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>{tittle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{oComponent}</Modal.Body>
      </Modal>

      <Row className="mb-4">
        <Col md={4}>
          <Button
            className="w-100"
            onClick={() =>{setShow(true); setRow({}); setComponent("CREAR")}}
          >
            Asociar metadato
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <Form.Group controlId="formEmpresa">
            <Form.Label>Empresa:</Form.Label>
            {isLoadingCompanies ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedCompany}
                onChange={handleCompanyChange}
              >
                <option value="">[SELECCIONE]</option>
                {companiesOptions.map((company) => (
                  <option key={company.value} value={company.value}>
                    {company.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formProceso">
            <Form.Label>Proceso:</Form.Label>
            {isLoadingCompanyProcess ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedProcess}
                onChange={handleAreaChange}
              >
                <option value="">[SELECCIONE]</option>
                {areasOptions.map((area) => (
                  <option key={area.value} value={area.value}>
                    {area.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formSerie">
            <Form.Label>Serie:</Form.Label>
            {isLoadingCompanySerie ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedSerie}
                onChange={handleSerieChange}
              >
                <option value="">[SELECCIONE]</option>
                {seriesOptions.map((serie) => (
                  <option key={serie.value} value={serie.value}>
                    {serie.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId="formMETADATO">
            <Form.Label>Tipo Documental:</Form.Label>
            {isLoadingCompanyType ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedType}
                onChange={handleTypeChange}
                disabled={false}
              >
                <option value="">[SELECCIONE]</option>
                {typeOptions.map((serie) => (
                  <option key={serie.value} value={serie.value}>
                    {serie.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formEmpresa">
            <Form.Label>Metadato:</Form.Label>
            {isLoadingCompanyMetadata ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedMetadata}
                onChange={handleMetadataChange}
              >
                <option value="">[SELECCIONE]</option>
                {metadataOptions.map((serie) => (
                  <option key={serie.value} value={serie.value}>
                    {serie.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button className="w-100" onClick={handleFilter}>
            Filtrar
          </Button>
        </Col>
        <Col md={2}>
          <Button className="w-100" onClick={handleClear}>
            Limpiar
          </Button>
        </Col>
      </Row>
      <Tablexxx
        columns={columnsTracking}
        URL={"tipometadatos?sorts=-REGFECMX,-REGHORMX"}
        buttons={buttons}
        setComponent={setComponent}
        setShow={setShow}
        setRow={setRow}
      />
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={5000}
        autohide
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 9999,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Notificación</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default Tabsproc;

