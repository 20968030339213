import { apiSlice } from "../apiSlice";

export const cretipxxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createType: builder.mutation({
      query: (parameters) => ({
        url: `tipos`,
        method: "POST",
        body: parameters,
      }),
    }),
    createCompanyAreaSerieType: builder.mutation({
      query: (parameters) => ({
        url: `serieportipos`,
        method: "POST",
        body: parameters,
      }),
    }),
    getTypeParent: builder.query({
      query: (parameters) => ({
        url: `/getTypeParent`,
        method: "GET",
        params: `filters[EMPNITXX]=${parameters.EMPNITXX}&filters[AREAIDXX]=${parameters.AREAIDXX}&filters[SERIEIDX]=${parameters.SERIEIDX}`
      }),
    }),
  }),
});

export const { useCreateTypeMutation, useCreateCompanyAreaSerieTypeMutation, useLazyGetTypeParentQuery } = cretipxxApiSlice;
