import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: "",
    token: null,
    isLoggedIn: false,
    isChangePass: false,
    infoUser: null,
    permissionUser: null,
  },
  reducers: {
    signIn: (state, action) => {
      const { token } = action.payload.userData.data[0];
      const { INFOUSER } = action.payload.userData.data[0];
      state.infoUser = INFOUSER;
      state.permissionUser = INFOUSER.USERPERM;
      state.token = token;
      state.isLoggedIn = true;
    },
    signOut: (state) => {
      state.user = "";
      state.token = null;
      state.isLoggedIn = false;
      state.isChangePass = false;
      state.infoUser = null;
      state.permissionUser = null;
    },
    changePass: (state, action) => {
      const { USRIDXXX } = action.payload.rejected.data.errors;
      state.user = USRIDXXX.toString();
      state.isChangePass = true;
    },
    reset: (state) => {
      state.user = "";
      state.token = null;
      state.isLoggedIn = false;
      state.isChangePass = false;
      state.infoUser = null;
      state.permissionUser = null;
    },
  },
});

export const { signIn, signOut, changePass, reset } = authSlice.actions;
export default authSlice.reducer;

export const getisLoggedIn = (state) => {
  return state.persistedReducer.isLoggedIn;
};

export const getUser = (state) => {
  return state.persistedReducer.user;
};

export const getDataUser = (state) => {
  return state.persistedReducer.infoUser;
};

export const getPermissionUser = (state) => {
  return state.persistedReducer.permissionUser;
};
export const getTokenUser = (state) => {
  return state.persistedReducer.token;
};

export const getisChangePass = (state) => {
  return state.persistedReducer.isChangePass;
};
