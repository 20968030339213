import React, { useState } from "react";
import { Form, Button, Row, Col, Modal, Spinner } from "react-bootstrap";
import { columnsTracking, buttons } from "./configxx";
import Tablexxx from "../Tablexxx";
import Cremetxx from "../../components/Cremetxx";
import { useGetMetadatosQuery, useGetMetadatosOpcQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import { useDispatch } from "react-redux";
import { setFilters } from "../../libs/redux/slices/filterSlice/filterSlice";


const Tabsproc = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [component, setComponent] = useState("");
  const [row, setRow] = useState({});
  const [selectedMetadata, setSelectedCompany] = useState("");
  const [selectedType, setSelectedType] = useState("");

  // Hook para obtener empresas
  const { data: metadata, isLoading: isLoadingMetadata } = useGetMetadatosQuery();

  // Hook para obtener inputType basado en la empresa seleccionada
  const { data: inputType, isLoading: isLoadingTipoCampo } = useGetMetadatosOpcQuery();


  const handleMetadataChange = (e) => {
    setSelectedCompany(e.target.value);
    setSelectedType("");
  };

  const handleFilter = () => {
    let filters = "filters[REGESTXX]=ACTIVO";
    if (selectedMetadata) {
      filters += `&filters[METIDXXX]=${selectedMetadata}`;
    }
    if (selectedType) {
      filters += `&filters[METTIPXX]=${selectedType}`;
    }
    dispatch(setFilters(filters));
  };

  const handleClear = () => {
    setSelectedCompany("");
    setSelectedType("");
    dispatch(setFilters(""));
  };

  let oComponent;
  let tittle;
  switch (component) {
    case "EDITAR":
      oComponent = <Cremetxx onClose={setShow} isCreating={false} row={row} isEdit={false} />;
      tittle = "EDITAR METADATO";
    break;
    case "VISUALIZAR":
      oComponent = <Cremetxx onClose={setShow} isCreating={false} row={row} isEdit={true} />;
      tittle = "VER METADATO";
    break;
    case "CREAR":
      oComponent = <Cremetxx onClose={setShow} isCreating={true} row={row} isEdit={false} />;
      tittle = "CREAR METADATO";
      break;
    default:
      break;
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>{tittle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{oComponent}</Modal.Body>
      </Modal>

      <Row className="mb-4">
        <Col md={4}>
          <Button
            className="w-100"
            onClick={() =>{setShow(true); setComponent("CREAR")}}

          >
            Crear metadato
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <Form.Group controlId="formEmpresa">
            <Form.Label>Metadato:</Form.Label>
            {isLoadingMetadata ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedMetadata}
                onChange={handleMetadataChange}
              >
                <option value="">[SELECCIONE]</option>
                {metadata?.data?.map((metadatos) => (
                  <option key={metadatos.METIDXXX} value={metadatos.METIDXXX}>
                    {metadatos.METDESXX}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formMETADATO">
            <Form.Label>Tipo Campo:</Form.Label>
            {isLoadingTipoCampo ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                disabled={isLoadingTipoCampo}
              >
                <option value="">[SELECCIONE]</option>
                {Object.entries(inputType?.data?.options).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button className="w-100" onClick={handleFilter}>
            Filtrar
          </Button>
        </Col>
        <Col md={2}>
          <Button className="w-100" onClick={handleClear}>
            Limpiar
          </Button>
        </Col>
      </Row>
      <Tablexxx
        columns={columnsTracking}
        URL={"metadatos?sorts=-REGFECMX,-REGHORMX"}
        buttons={buttons}
        setComponent={setComponent}
        setShow={setShow}
        setRow={setRow}
      />
    </>
  );
};

export default Tabsproc;

