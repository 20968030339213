export const columnsTracking = [
  { id: "idEmpresa", title: "Empresa", width: "15%", position: "EMPDESXX" },
  { id: "idProceso", title: "Proceso", width: "15%", position: "AREADESX" },
  { id: "idSerie", title: "Serie", width: "15%", position: "SERIEDES" },
  { id: "IdTipo", title: "Tipo", width: "10%", position: "TIPODESX" },
  { id: "idMetadato", title: "Metadato", width: "10%", position: "METDESXX" },
  { id: "idModificadoPor", title: "Modificado Por", width: "15%", position: "USRNOMXX" },
  { id: "idFechaModificacion", title: "Fecha de modificación", width: "12%", position: "REGFECMX" },
];

export const buttons = [
  { id: "idVerProceso", class: "bi bi-eye-fill", title: "Ver" },
  { id: "idEditarProceso", class: "bi bi-pencil-square", title: "Editar" },
];
