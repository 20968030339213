import React, { useEffect } from "react";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import {
  getObjectErrors,
  setShowErrors,
  getShowError,
  getDictionaryErrors,
} from "../../libs/redux/slices/errorSlice/errorSlice";
import { useSelector, useDispatch } from "react-redux";
const Errorsx = () => {
  let errorMessage = "";
  const dispatch = useDispatch();
  const oErrors = useSelector(getObjectErrors);
  const showToast = useSelector(getShowError);
  const dictionaryErrors = useSelector(getDictionaryErrors);
  const propertys = Object.getOwnPropertyNames(oErrors);
  if (oErrors?.message) {
    errorMessage = oErrors?.message;
  } else {
    if (Object.entries(dictionaryErrors).length === 0) {
      propertys.forEach((property) => {
        errorMessage += property + " " + oErrors[property] + "\n";
      });
    } else if (Object.entries(dictionaryErrors).length > 0) {
      propertys.forEach((property) => {
        if (property !== "length") {
          let propertySpaces = "";
          for (let i = 0; i < property.length; i++) {
            propertySpaces += property.toLocaleLowerCase().charAt(i) + " ";
          }
          let cError = oErrors[property][0];
          errorMessage +=
            dictionaryErrors[property] +
            ": " +
            cError.toLocaleLowerCase().replace(propertySpaces, dictionaryErrors[property] + " ") +
            "\n";
        }
      });
    }
  }

  useEffect(() => {
    if (!showToast) {
      setTimeout(() => {
        dispatch(setShowErrors(false));
      }, 5000);
    }
  }, [showToast, dispatch]);

  return (
    <ToastContainer className="p-3" position="top-end">
      <Toast onClose={() => dispatch(setShowErrors(false))} show={showToast} delay={5000} autohide>
        <Toast.Header bg="error">
          <strong className="me-auto">Errores</strong>
        </Toast.Header>
        <Toast.Body>{errorMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Errorsx;
