import DataTable from "react-data-table-component";
import { getQueue, removeFileToQueue } from "../../libs/redux/slices/queueSlice/queueSlice";
import { useSelector, useDispatch } from "react-redux";
import { columnsTransform } from "./columnsTransform";

import fileSlice from "../../libs/localforage/slices/fileSlice/fileSlice";

const Fileuser = () => {
  const fileClass = new fileSlice();
  const dataQueue = useSelector(getQueue);
  const dispatch = useDispatch();
  const optionsPagination = {
    rowsPerPageText: "Filas por pagina",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  /*
  const startFile = (previewRow, previewIndex) => {
    if (!inProgress) {
      const newRow = { ...previewRow };
      newRow.INPROCES = true;
      dispatch(updatePositionInQueue({ dataQueue, index: previewIndex, row: newRow }));
      dispatch(setInProgress({ inProgress: true, indexInprogress: previewIndex }));
    }
  };

  const pauseFile = (previewRow, previewIndex) => {
    const newRow = { ...previewRow };
    newRow.INPROCES = false;
    dispatch(updatePositionInQueue({ dataQueue, index: previewIndex, row: newRow }));
    dispatch(setInProgress({ inProgress: false, indexInprogress: previewIndex }));
  };
  */

  const removeFile = async (row, index) => {
    await fileClass.removeFileByKey(row.FILEINDE).then(() => dispatch(removeFileToQueue({ index, dataQueue })));
  };

  const columns = columnsTransform(
    [
      { id: "idNombre", title: "Nombre Del Archivo", width: "40%", position: "NAMEXXXX" },
      { id: "idPeso", title: "Peso KB", width: "30%", position: "SIZEKBXX" },
      { id: "idPorcentaje", title: "Porcentaje", width: "20%", position: "PORCENXX" },
    ],
    removeFile
  );

  return (
    <DataTable
      noDataComponent="No se Encontraron Registros"
      columns={columns}
      data={dataQueue}
      pagination
      paginationComponentOptions={optionsPagination}
    />
  );
};

export default Fileuser;
