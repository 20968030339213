import React from "react";

//bootstrap
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

//importo redux
import { useSelector, useDispatch } from "react-redux";
import { getDataUser, signOut, reset } from "../../libs/redux/slices/authSlice/authSlice";
import { useLogoutMutation } from "../../libs/redux/slices/authSlice/authApiSlice";
import { resetQueue } from "../../libs/redux/slices/queueSlice/queueSlice";
import { NavDropdown } from "react-bootstrap";

import { PersonCircle } from "react-bootstrap-icons";

import fileSlice from "../../libs/localforage/slices/fileSlice/fileSlice";

const Navbarxx = ({ path }) => {
  const fileClass = new fileSlice();
  const userData = useSelector(getDataUser);
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const f_Salir = async () => {
    await fileClass.deleteAll().then(async () => {
      await logout();
      dispatch(signOut());
      dispatch(reset());
      dispatch(resetQueue());
    });
  };

  return (
    <Navbar>
      <Container>
        <Navbar.Collapse>
          <Nav fill variant="tabs" className="text-truncate w-100">
            <Nav.Item>
              <Nav.Link href="#/home" active={path === "home" ? true : false}>
                <h4>Cargue Documental</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#/process" active={path === "process" ? true : false}>
                <h4>Cargues en Proceso</h4>
              </Nav.Link>
            </Nav.Item>
            {userData.USRADMGE === "SI" ? (
              <>
                <Nav.Item>
                  <Nav.Link href="#/admin" active={path === "admin" ? true : false}>
                    <h4>Administrar</h4>
                  </Nav.Link>
                </Nav.Item>

               <Nav.Item>
                <Nav.Link href="#/percargo" active={path === "percargo" ? true : false}>
                  <h4>Permisos Cargo</h4>
                </Nav.Link>
              </Nav.Item>

                <Nav.Item>
                  <Nav.Link href="#/peruser" active={path === "peruser" ? true : false}>
                    <h4>Permisos Usuario</h4>
                  </Nav.Link>
                </Nav.Item>
              </>

            ) : (
              ""
            )}
          </Nav>
          <Nav className="justify-content-end flex-grow-1">
            <NavDropdown title={<PersonCircle size={39} style={{ cursor: "pointer", color: "white" }} />} align="end">
              <NavDropdown.Item className="disabled text-truncate">{userData.USRNOMXX}</NavDropdown.Item>
              <NavDropdown.Item>
                <Button onClick={f_Salir} className="m-1 w-100">
                  Salir
                </Button>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbarxx;
