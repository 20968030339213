import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `/login`,
        method: "POST",
        body: {
          USRIDXXX: credentials.name,
          USRPASXX: credentials.password,
          SISTEMAX: process.env.REACT_APP_GENIO_NAME,
        },
        credentials: "include",
      }),
    }),
    restorePassword: builder.mutation({
      query: (credentials) => ({
        url: "/restorePassword",
        method: "POST",
        body: {
          USRIDXXX: credentials.USRIDXXX,
          USRPASXX: credentials.pass1,
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
    }),
    getInfoUser: builder.mutation({
      query: () => ({
        url: `/userinfo?system=GESTORLOCAL`,
        method: "GET",
      }),
      credentials: "include",
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
export const { useLogoutMutation } = authApiSlice;
export const { useRestorePasswordMutation } = authApiSlice;
export const { useGetInfoUserMutation } = authApiSlice;
