import { createSlice } from "@reduxjs/toolkit";

const cimaxxxxSlice = createSlice({
  name: "cima",
  initialState: {
    token: null
  },
  reducers: {
    setTokenCima: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setTokenCima } = cimaxxxxSlice.actions;
export default cimaxxxxSlice.reducer;

export const getTokenCima = (state) => {
  return state.cima.token;
}
