import React from "react";

import Navbarxx from "../components/Navbarxx";
import Selectop from "../components/Selectop";
import { Container, Row, Col, Image } from "react-bootstrap";

const HomePage = () => {
  return (
    <>
      <Navbarxx path={"home"} />
      <Container>
        <Row className="justify-content-md-center">
          <Col xxl={4} xl={6} md={10} className="align-self-center mt-1">
            <Image
              src="https://gestordocumental.repremundo.com.co/sites/default/files/Genio%20documental1-33.png"
              width="95%"
            />
          </Col>
        </Row>
        <Row>
          <Selectop />
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
