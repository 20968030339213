import { apiSlice } from "../apiSlice";

export const crearexxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createArea: builder.mutation({
      query: (parameters) => ({
        url: `areas`,
        method: "POST",
        body: parameters,
      }),
    }),
    createCompanyAreas: builder.mutation({
      query: (parameters) => ({
        url: `empresaAreas`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
    updateCompanyArea: builder.mutation({
      query: (parameters) => ({
        url: `areasEmpresa`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
    getCompanyAreas: builder.query({
      query: (areaId) => ({
        url: `areasEmpresa/${areaId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useCreateAreaMutation, useCreateCompanyAreasMutation, useUpdateCompanyAreaMutation, useGetCompanyAreasQuery } = crearexxApiSlice;
