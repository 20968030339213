export const columnsTracking = [
  { id: "idMetadato", title: "Metadato", width: "15%", position: "METDESXX" },
  { id: "idProceso", title: "Tipo", width: "20%", position: "METTIPXX" },
  { id: "idOpcAsigs", title: "Opciones asignadas", width: "20%", position: "METOPXXX" },
  { id: "idModificadoPor", title: "Modificado Por", width: "20%", position: "REGUSRNM" },
  { id: "idFechaModificacion", title: "Fecha de modificación", width: "15%", position: "REGFECMX" },
];

export const buttons = [
  { id: "idVerProceso", class: "bi bi-eye-fill", title: "Ver" },
  { id: "idEditarProceso", class: "bi bi-pencil-square", title: "Editar" },
];
