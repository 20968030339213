import React, { useState } from "react";
//Bootstrap
import { Form, InputGroup, Button, Spinner, Col, Row } from "react-bootstrap";

//bootrap icon
import { EyeFill } from "react-bootstrap-icons";

//redux
import { signIn, changePass } from "../../libs/redux/slices/authSlice/authSlice";
import { useLoginMutation } from "../../libs/redux/slices/authSlice/authApiSlice";
import { useDispatch } from "react-redux";
import { useLoginCimaMutation } from "../../libs/redux/slices/cimaxxxx/cimaxxxxApiSlice";
import { setTokenCima } from "../../libs/redux/slices/cimaxxxx/cimaxxxxSlice";

const Loginxxx = () => {
  //Estados para los input y mensajes de error basicos
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const [logCima] = useLoginCimaMutation();

  const f_Login = async (e) => {
    e.preventDefault();
    await login({ name, password })
      .unwrap()
      .then(async (userData) => {
        dispatch(signIn({ name, userData }));
        const { data } = await logCima({ "USRIDXXX": userData?.data?.[0].INFOUSER.USRIDXXX.toString() });
        if (data) {
          dispatch(setTokenCima(data.data[0].token));
        }
      })
      .catch((rejected) => {
        if (rejected.status) {
          dispatch(changePass({ name, rejected }));
        }
      });
  };

  return isLoading ? (
    <Spinner animation="grow" />
  ) : (
    <Form className="text-start w-100 mx-auto border border-5 classFromLogin" onSubmit={f_Login}>
      <Form.Group className="mb-3 mt-5 w-80 m-5">
        <Form.Label>Email / Identificación</Form.Label>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Email / Identificación"
          required
        />
      </Form.Group>
      <Row>
        <Col className="ms-5">
          <Form.Label>Contraseña</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="ms-5 me-5 w-auto">
            <Form.Control
              type={!showPassword ? "password" : "text"}
              placeholder="Contraseña"
              aria-label="idPassword"
              aria-describedby="idPassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <InputGroup.Text>
              <EyeFill onClick={() => setShowPassword(!showPassword)} />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Form.Group className="mb-5 mt-5 text-center">
        <Button type="submit">INICIAR SESIÓN</Button>
      </Form.Group>
    </Form>
  );
};

export default Loginxxx;
