export const columnsTracking = [
  { id: "idUsuario", title: "ID", width: "10%", position: "USRIDXXX" },
  { id: "idNombre", title: "Nombre Usuario", width: "25%", position: "USRNOMXX" },
  { id: "idEmpresa", title: "Empresa", width: "20%", position: "EMPDESXX" },
  { id: "idModificado", title: "Modificado Por", width: "15%", position: "REGUSRNO" },
  { id: "idFechaMod", title: "Fecha de Modificación", width: "15%", position: "REGSTAMP" }
  ];


export const buttons = [
  { id: "idEliminarUsuario", class: "bi bi-trash" , title: "Eliminar" },
  { id: "idVerPermisos", class: "bi bi-eye-fill" , title: "Ver" },
  { id: "idEditarPermisos", class: "bi bi-pencil-square" , title: "Editar" },
  { id: "idClonarPermisos", class: "bi bi-files" , title: "Clonar" },
];
