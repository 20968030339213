export const columnsTracking = [
  { id: "idEmpresa", title: "Empresa", width: "20%", position: "EMPDESXX" },
  { id: "idProceso", title: "Proceso", width: "20%", position: "AREADESX" },
  { id: "idSerie", title: "Serie", width: "15%", position: "SERIEDES" },
  { id: "idModificadoPor", title: "Modificado Por", width: "20%", position: "REGUSRNM" },
  { id: "idFechaModificacion", title: "Fecha de modificación", width: "15%", position: "REGFECMX" },
];

export const buttons = [
  { id: "idVerProceso", class: "bi bi-eye-fill", title: "Ver" },
  { id: "idEditarProceso", class: "bi bi-pencil-square", title: "Editar" },
];
