import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPagesSplit, getPagesSplit } from "../../libs/redux/slices/splitpdfSlice/splitpdfSlice";

const Splitpdf = ({ numPages, index, nameFile }) => {
  const [initialList, setInitialList] = useState([]);
  const [pagesCheck, setPagesCheck] = useState([]);
  const dispatch = useDispatch();
  const pagesSplit = useSelector(getPagesSplit);
  useEffect(() => {
    dispatch(setPagesSplit({ listPages: [], index }));
  }, [index, dispatch]);

  useEffect(() => {
    for (let i = 1; i <= numPages; i++) {
      setInitialList((list) => [...list, i]);
    }
  }, [numPages]);
  //Evento para los checks
  const onSetPages = (e, type, page) => {
    if (type === "all") {
      if (e.target.checked) {
        setPagesCheck(initialList);
        dispatch(setPagesSplit({ listPages: initialList, index }));
      } else {
        setPagesCheck([]);
        dispatch(setPagesSplit({ listPages: [], index }));
      }
    } else {
      if (e.target.checked) {
        setPagesCheck((state) => [...state, page]);
        dispatch(setPagesSplit({ listPages: [...pagesSplit[index], page], index }));
      } else {
        setPagesCheck((state) => state.filter((item) => item !== page));
        dispatch(setPagesSplit({ listPages: pagesSplit[index].filter((item) => item !== page), index }));
      }
    }
  };
  return (
    <>
      {initialList.length > 0 && (
        <>
          <p>{nameFile}</p>
          <Form.Check
            type="checkbox"
            label="Seleccionar Todos"
            onClick={(e) => {
              onSetPages(e, "all");
            }}
          />
          {initialList.map((page) => (
            <Form.Check
              key={page}
              type="checkbox"
              label={`Pagina ${page}`}
              name="SPLITPDF"
              value="SI"
              checked={pagesCheck.includes(page)}
              onChange={(e) => {
                onSetPages(e, "", page);
              }}
            />
          ))}
        </>
      )}
    </>
  );
};

export default Splitpdf;
