import { useEffect, useState } from "react";
import {
  useCreateDocumentaryPackageMutation,
  useCreatePackagePresignedMutation,
  useConfirmPackagePresignedMutation,
} from "../../libs/redux/slices/fileSlice/fileApiSlice";

import {
  getQueue,
  getInProgress,
  removeFileToQueue,
  setInProgress,
  updatePositionInQueue,
} from "../../libs/redux/slices/queueSlice/queueSlice";

import { useSelector, useDispatch } from "react-redux";

import fileSlice from "../../libs/localforage/slices/fileSlice/fileSlice";
import { setShowMessage } from "../../libs/redux/slices/confirmxxSlice/confirmxxSlice";

const Direuplo = () => {
  const dispatch = useDispatch();
  const inProgress = useSelector(getInProgress);
  const [petitionInProgress, setPetitionInProgress] = useState(false);
  const [createDocumentaryPackage] = useCreateDocumentaryPackageMutation();
  const [createPackagePresigned] = useCreatePackagePresignedMutation();
  const [confirmPackagePresigned] = useConfirmPackagePresignedMutation();
  const dataQueue = useSelector(getQueue);
  const [rowError, setRowError] = useState(false);
  const fileClass = new fileSlice();

  const removeFile = async (key) => {
    await fileClass.removeFileByKey(key).then(() => {
      dispatch(removeFileToQueue({ index: 0, dataQueue }));
      dispatch(setInProgress({ inProgress: false, indexInprogress: null }));
      setPetitionInProgress(false);
    });
  };

  const createPackage = async (rowInProgress) => {
    setPetitionInProgress(true);
    await fileClass.findFileByKey(rowInProgress.FILEINDE).then(async (fileBase64) => {
      if (fileBase64 !== "") {
        await createDocumentaryPackage({
          EMPNITXX: rowInProgress.EMPNITXX,
          AREAIDXX: rowInProgress.AREAIDXX,
          SERIEIDX: rowInProgress.SERIEIDX,
          TIPOIDXX: rowInProgress.TIPOIDXX,
          JSONMETX: rowInProgress.JSONMETX,
          FILEBS64: fileBase64,
          TYPEXXXX: rowInProgress.TYPEXXXX,
          NAMEXXXX: rowInProgress.NAMEXXXX,
          PAGSPLIT: rowInProgress.PAGSPLIT,
        })
          .unwrap()
          .then(() => {
            removeFile(rowInProgress.FILEINDE);
            dispatch(setShowMessage({ message: "Cargue exitoso del documento: " + rowInProgress.NAMEXXXX }));
          })
          .catch(() => setRowError(!rowError));
      }
    });
  };

  const getPresignedPackage = async (rowInProgress) => {
    setPetitionInProgress(true);
    await createPackagePresigned({
      EMPNITXX: rowInProgress.EMPNITXX,
      AREAIDXX: rowInProgress.AREAIDXX,
      SERIEIDX: rowInProgress.SERIEIDX,
      TIPOIDXX: rowInProgress.TIPOIDXX,
      JSONMETX: rowInProgress.JSONMETX,
      TYPEXXXX: rowInProgress.TYPEXXXX,
      NAMEXXXX: rowInProgress.NAMEXXXX,
    })
      .then(async (response) => {
        let newRow = { ...rowInProgress };
        newRow.URLPRESI = response.data.data.url;
        newRow.IDARCHXX = response.data.data.IDARCHXX;
        dispatch(updatePositionInQueue({ dataQueue, index: 0, row: newRow }));
        await uploadPresignedFile(newRow);
      })
      .catch(() => setRowError(!rowError));
  };

  const uploadPresignedFile = async (rowInProgress) => {
    setPetitionInProgress(true);
    await fileClass.findFileByKey(rowInProgress.FILEINDE).then(async (file) => {
      var requestOptions = {
        method: "PUT",
        body: file,
        redirect: "follow",
      };

      fetch(rowInProgress.URLPRESI, requestOptions)
        .then(async () => {
          await confirmUploadPresigned(rowInProgress);
        })
        .catch(() => setRowError(!rowError));
    });
  };

  const confirmUploadPresigned = async (rowInProgress) => {
    setPetitionInProgress(true);
    await confirmPackagePresigned({ path: { IDARCHXX: rowInProgress.IDARCHXX }, body: { REGESTXX: "ACTIVO", PAGSPLIT: rowInProgress.PAGSPLIT } })
      .then(() => {
        removeFile(rowInProgress.FILEINDE);
        dispatch(setShowMessage({ message: "Cargue exitoso del documento: " + rowInProgress.NAMEXXXX }));
      })
      .catch(() => setRowError(!rowError));
  };

  //useeffect para reorganizar la cola
  useEffect(() => {
    let rowInUpload = dataQueue.filter((file) => file.INTENTOS <= 3)[0];
    if (dataQueue.length > 0 && typeof rowInUpload !== "undefined") {
      let newOrder = [];
      Object.entries(dataQueue).forEach((row, index) => {
        if (index !== 0) {
          //agendo el proximo
          if (index === 1 ) {
            let newRo1 = { ...row[1] };
            newRo1.INPROCES = true;
            newOrder.push(newRo1);
          } else {
            newOrder.push(row[1]);
          }
        }
      })
      let newRo = { ...dataQueue[0] };
      newRo.INPROCES = false;
      newRo.INTENTOS = newRo.INTENTOS + 1;
      newOrder.push(newRo);
      dispatch(updatePositionInQueue({ order: newOrder }));
      dispatch(setInProgress({ inProgress: false }));
      setPetitionInProgress(false);
    }
    // eslint-disable-next-line
  }, [rowError])

  //agendo el primero para el envio
  useEffect(() => {
    let rowInUpload = dataQueue.filter((file) => file.INTENTOS <= 3)[0];
    if (!inProgress && dataQueue.length > 0 && !petitionInProgress && typeof rowInUpload !== "undefined") {
      let newRow = { ...dataQueue[0] };
      if (newRow.INTENTOS <= 3) {
        dispatch(setInProgress({ inProgress: true, indexInprogress: 0 }));
        newRow.INPROCES = true;
        dispatch(updatePositionInQueue({ dataQueue, index: 0, row: newRow }));
      } else {
        setRowError(!rowError);
      }
    }
    // eslint-disable-next-line
  }, [inProgress, petitionInProgress, dataQueue]);

  //proceso la cola para los que son base64
  useEffect(() => {
    let rowInProgress = dataQueue.filter((file) => file.INPROCES)[0];
    if (typeof rowInProgress !== "undefined" && rowInProgress.ISBASE64 && inProgress && !petitionInProgress) {
      createPackage(rowInProgress);
    }
    // eslint-disable-next-line
  }, [dataQueue, inProgress, petitionInProgress]);

  //proceso la cola para los que no son base64
  useEffect(() => {
    let rowInProgress = dataQueue.filter((file) => file.INPROCES)[0];
    if (typeof rowInProgress !== "undefined" && !rowInProgress.ISBASE64 && inProgress && !petitionInProgress) {
      if (rowInProgress.URLPRESI === "") {
        getPresignedPackage(rowInProgress);
      }
    }
    // eslint-disable-next-line
  }, [dataQueue, inProgress, petitionInProgress]);
};

export default Direuplo;
