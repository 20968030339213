import { apiSlice } from "../apiSlice";

export const creapermApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    setSavePermisionCargo: builder.mutation({
      query: (parameters) => ({
        url: `/cargosporareaserietipo`,
        method: "POST",
        body: parameters,
      }),
    }),
    getPermisionByCarid: builder.query({
      query: (parameters) => ({
        url: `/cargosporareaserietipo/${parameters.CARIDXXX}/${parameters.EMPNITXX}`,
        method: "GET",
        params: { ...parameters,
          per_page: 300 },
      }),
    }),
    setUpdatePermisionCargo: builder.mutation({
      query: (parameters) => ({
        url: `/cargosporareaserietipo/${parameters.CARIDXXX}`,
        method: "PUT",
        body: parameters,
      }),
    }),
    deletePermisionByCargo: builder.mutation({
      query: (parameters) => ({
        url: `/cargosporareaserietipo/${parameters.CARIDXXX}/${parameters.EMPNITXX}`,
        method: "PUT",
        body: parameters,
      }),invalidatesTags: ["reloadTable"],
    }),
    InactivatePermisionByCargo: builder.mutation({
      query: (parameters) => ({
        url: `/inactivatecarareaserietipo/${parameters.TIPOOPEX}/${parameters.CARIDXXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}/${parameters.TIPOIDXX}/${parameters.EMPNITXX}`,
        method: "PUT",
        body: parameters,
      }),invalidatesTags: ["reloadTable"],
    }),
  }),
});

export const {
  useSetSavePermisionCargoMutation,
  useLazyGetPermisionByCaridQuery,
  useSetUpdatePermisionCargoMutation,
  useDeletePermisionByCargoMutation,
  useInactivatePermisionByCargoMutation
 } = creapermApiSlice;
