import Tablexxx from "../Tablexxx"
import { buttons, columnsTracking } from "./configxx";

const Tabsperu = ({ setComponent, setShow, setRow }) => {
  return (
    <Tablexxx
      columns={columnsTracking}
      URL={"usuariosporareaserietipo?sorts=-REGSTAMP&groups=EMPNITXX,USRIDXXX"}
      buttons={buttons}
      setComponent={setComponent}
      setShow={setShow}
      setRow={setRow}
    />
  )
}

export default Tabsperu;
