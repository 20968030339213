import { createSlice } from "@reduxjs/toolkit";

const queueSlice = createSlice({
  name: "queue",
  initialState: {
    inProgress: false,
    indexInprogress: null,
    queue: [],
  },
  empnitxx:"",
  reducers: {
    addFileToQueue(state, action) {
      state.queue.push(action.payload);
    },
    removeFileToQueue(state, action) {
      state.queue = action.payload.dataQueue.filter((row, index) => {
        return index !== action.payload.index ?? row;
      });
    },
    setInProgress(state, action) {
      state.inProgress = action.payload.inProgress;
      state.indexInprogress = action.payload.indexInprogress;
    },
    updatePositionInQueue(state, action) {
      state.queue = {};
      if (action.payload.order) {
        state.queue = action.payload.order;
      } else {
        state.queue = action.payload.dataQueue.map((file, index) => {
          return index === action.payload.index ? action.payload.row : file;
        });
      }
    },
    resetQueue(state) {
      state.inProgress = false;
      state.indexInprogress = null;
      state.queue = [];
    },
    addArrayToQueue(state, action) {
      action.payload.forEach((file) => {
        state.queue.push(file);
      });
    },
    setEmpnitxx(state, action) {
      state.empnitxx = action.payload;
    },
  },
});
export const { addFileToQueue,
  removeFileToQueue,
  setInProgress,
  updatePositionInQueue,
  resetQueue,
  addArrayToQueue,
  setPetitionProgress,
  setEmpnitxx
} =  queueSlice.actions;

export default queueSlice.reducer;

export const getQueue = (state) => {
  return state.persistedQueueReducer.queue;
};

export const getInProgress = (state) => {
  return state.persistedQueueReducer.inProgress;
};

export const getIndexInProgress = (state) => {
  return state.persistedQueueReducer.indexInprogress;
};

export const getEmpnitxx = (state) => {
  return state.persistedQueueReducer.empnitxx;
};
