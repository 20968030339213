import localforage from "localforage";

localforage.config({
  driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: process.env.REACT_APP_GENIO_NAME,
  version: 1.0,
  storeName: "files", // Should be alphanumeric, with underscores.
  description: "DB encargada de almacenar las partes de los archivos",
});

export default localforage;
