import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Modal, Spinner } from "react-bootstrap";
import { columnsTracking, buttons } from "./configxx";
import Tablexxx from "../Tablexxx";
import Crearexx from "../../components/Crearexx";
import { useGetCompanysQuery, useLazyGetAreasByCompanyQuery, useGetProcesoQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import { useDispatch } from "react-redux";
import { setFilters } from "../../libs/redux/slices/filterSlice/filterSlice";


const Tabsproc = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [component, setComponent] = useState("");
  const [row, setRow] = useState({});
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [areasOptions, setAreasOptions] = useState([]);
  const [trigger, setTrigger] = useState(false);

  const { data: globalProcesos, isLoading: isLoadingGlobalProcesos } = useGetProcesoQuery();
  // Hook para obtener empresas
  const { data: companies, isLoading: isLoadingCompanies } = useGetCompanysQuery();

  // Hook para obtener areas basado en la empresa seleccionada
  const [triggerGetAreas, { data: areas, isLoading: isLoadingAreas }] = useLazyGetAreasByCompanyQuery();

  useEffect(() => {
    if (selectedCompany) {
      // Ejecuta el fetch de areas cuando una empresa es seleccionada
      triggerGetAreas({ EMPNITXX: selectedCompany, AREAIDXX: "null" });
    }
  }, [selectedCompany, triggerGetAreas]);

  useEffect(() => {
    if (areas?.data ) {
      const uniqueAreas = Array.from(new Set(areas?.data.map(proceso => proceso.AREAIDXX)))
        .map(value => {
          const proceso = areas?.data.find(p => p.AREAIDXX === value);
          return { label: proceso.AREADESX, value: proceso.AREAIDXX };
        });

      setAreasOptions(uniqueAreas);
    }
  }, [areas]);

  useEffect(() => {
    if (globalProcesos?.data) {
      setAreasOptions(
        Array.from(new Set(globalProcesos.data.map(proceso => proceso.AREAIDXX)))
          .map(value => {
            const proceso = globalProcesos.data.find(p => p.AREAIDXX === value);
            return { label: proceso.AREADESX, value: proceso.AREAIDXX };
          })
      );
    }
  }, [globalProcesos, trigger]);

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    setSelectedArea("");
  };

  const handleFilter = () => {
    let filters = "filters[REGESTXX]=ACTIVO";
    if (selectedCompany) {
      filters += `&filters[EMPNITXX]=${selectedCompany}`;
    }
    if (selectedArea) {
      filters += `&filters[AREAIDXX]=${selectedArea}`;
    }
    dispatch(setFilters(filters));
  };

  const handleClear = () => {
    setSelectedCompany("");
    setSelectedArea("");
    triggerGetAreas(null);
    dispatch(setFilters("filters[REGESTXX]=ACTIVO"));
    setTrigger(prevTrigger => !prevTrigger);
  };

  let oComponent;
  let tittle;
  switch (component) {
    case "EDITAR":
      oComponent = <Crearexx onClose={setShow} isCreating={false} row={row} isEdit={false} />;
      tittle = "EDITAR PROCESO";
    break;
    case "VISUALIZAR":
      oComponent = <Crearexx onClose={setShow} isCreating={false} row={row} isEdit={true} />;
      tittle = "VER PROCESO";
    break;
    case "CREAR":
      oComponent = <Crearexx onClose={setShow} isCreating={true} row={row} isEdit={false} />;
      tittle = "CREAR PROCESO";
      break;
    default:
      break;
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>{tittle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{oComponent}</Modal.Body>
      </Modal>

      <Row className="mb-4">
        <Col md={4}>
          <Button
            className="w-100"
            onClick={() =>{setShow(true); setComponent("CREAR")}}

          >
            Crear Proceso
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <Form.Group controlId="formEmpresa">
            <Form.Label>Empresa:</Form.Label>
            {isLoadingCompanies ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedCompany}
                onChange={handleCompanyChange}
              >
                <option value="">[SELECCIONE]</option>
                {companies?.data?.map((company) => (
                  <option key={company.EMPNITXX} value={company.EMPNITXX}>
                    {company.EMPNOMXX}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formProceso">
            <Form.Label>Proceso:</Form.Label>
            {isLoadingAreas || isLoadingGlobalProcesos ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedArea}
                onChange={(e) => setSelectedArea(e.target.value)}
                disabled={isLoadingAreas}
              >
                <option value="">[SELECCIONE]</option>
                {areasOptions.map((area) => (
                  <option key={area.value} value={area.value}>
                    {area.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button className="w-100" onClick={handleFilter}>
            Filtrar
          </Button>
        </Col>
        <Col md={2}>
          <Button className="w-100" onClick={handleClear}>
            Limpiar
          </Button>
        </Col>
      </Row>
      <Tablexxx
        columns={columnsTracking}
        URL={"empresaAreas?sorts=-REGFECMX,-REGHORMX"}
        buttons={buttons}
        setComponent={setComponent}
        setShow={setShow}
        setRow={setRow}
      />
    </>
  );
};

export default Tabsproc;

