import { Accordion, Badge, Button, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { setUserSelected } from "../../libs/redux/slices/filterSlice/filterSlice";
import { useLazyGetUserQuery } from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../libs/redux/slices/apiSlice";
import { setShowMessage } from "../../libs/redux/slices/confirmxxSlice/confirmxxSlice";
import { useState, useEffect, useRef, useCallback} from "react";
import React from 'react';
import { useGetCompanysQuery,
  useLazyGetAreasByCompanyQuery,
  useLazyGetSeriesByAreaQuery,
  useLazyGetTypesBySerieQuery
} from "../../libs/redux/slices/filterSlice/filterApiSlice";
import { useSetSavePermisionUserMutation,
  useInactivatePermisionByUserMutation,
  useLazyGetPermisionByUserQuery
} from "../../libs/redux/slices/crepermuSlice/creaprusApiSlice";

const Asigperu = ({ onClose, component, row, visual }) => {
  const [findUser, {isLoading : loaduser}] = useLazyGetUserQuery();
  const [ setSaveData ] = useSetSavePermisionUserMutation();
  const [ setInaData ] = useInactivatePermisionByUserMutation();
  const [options, setOptions] = useState([]);
  const [usuarioSelc, setUserSelc] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [areaSelected, setAreaSelected] = useState("");
  const [serieSelected, setSerieSelected] = useState("");
  const [regDes, setregDes] = useState(0);
  const [dataRowSelct, setDataRowSelct] = useState([{"AREAIDXX": "", "SERIEIDX": "", "TIPOIDXX": [], "regDes": regDes}]);
  const [dataRowPrimer, setDataRowPrimer] = useState([{"AREAIDXX": "", "SERIEIDX": "", "TIPOIDXX": [], "regDes": regDes}]);
  const [keyRow, setKeyRow] = useState("");
  const { data: dataempresas, isLoading: isLoadEmpr } = useGetCompanysQuery();
  const [ seriesByKey, setSeriesByKey ] = useState([[]]);
  const [ typesByKey, setTypesByKey ] = useState([[]]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [regActu, setregActu] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [flatPerm, setFlatPerm] = useState([]);
  const [ totalAreas, setTotalAreas ] = useState([[]]);
  const [ loadSave, setLoadsave ] = useState(false);
  const [findAreasByCompany, { data: areas, isLoading: isLoadingAreas, isUninitialized: isUninitializedAreas }] = useLazyGetAreasByCompanyQuery();
  const [ findSeriesByArea ] = useLazyGetSeriesByAreaQuery();
  const [ findTypesBySerie, { isLoading: isLoadingTypes, isUninitialized: isUninitializedTypes }] = useLazyGetTypesBySerieQuery();
  const [ findPermByUser ] = useLazyGetPermisionByUserQuery();
  const [ edit, setEdit] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const dispatchUser = useDispatch();
  const dispatch = useDispatch();
  const memoizedRow = useDeepCompareMemoize(row);
  const initialized = useRef(false);
  const searchUser = async (value) => {
    if (value !== "") {
      try {
        const response = await findUser(value);
        let form = [];
        if (response?.data) {
          form = response.data.map((usuario) => {
            return { id: usuario.USRIDXXX, label: usuario.USRNOMXX };
          });
        }
        setOptions(form);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
  }
  useEffect(() => {
    if (companySelected !== "") {
      setAreaSelected("");
      findAreasByCompany({ EMPNITXX: companySelected, AREAIDXX: "null" });
    }
  }, [companySelected, findAreasByCompany, setAreaSelected]);

  //use effect cuando se selecciona un area
  useEffect(() => {
    if (companySelected !== "" && areaSelected !== "") {
      const findSerie = async () => {
        let dataSer = await findSeriesByArea({ EMPNITXX: companySelected, AREAIDXX: areaSelected, SERIEIDX: "null" });
        setSeriesByKey(seriesByKey.map((row, key) => {
          if (key === keyRow) {
            return dataSer?.data?.data;
          } else {
            return row;
          }
        }));
      }
      setDataRowSelct(dataRowSelct.map((row, key) => {
        if (key === keyRow) {
          return { ...row, "AREAIDXX": areaSelected};
        } else {
          return row;
        }
      }));
      findSerie();
    }
    // eslint-disable-next-line
  }, [areaSelected, setAreaSelected, trigger]);

  //useeffect cuando se selecciona una serie
  useEffect(() => {
    if (serieSelected !== "") {
      const findTypes = async () => {
        let dataTyps = await findTypesBySerie({ EMPNITXX: companySelected, AREAIDXX: dataRowSelct[keyRow].AREAIDXX, SERIEIDX: serieSelected, TIPOIDXX: "null" });
        setTypesByKey(typesByKey.map((row, key) => {
          if (key === keyRow) {
            return dataTyps?.data?.data;
          } else {
            return row;
          }
        }));
      }
      setDataRowSelct(dataRowSelct.map((row, key) => {
        if (key === keyRow) {
          return { ...row, "SERIEIDX": serieSelected, TIPOIDXX: []};
        } else {
          return row;
        }
      }));
      findTypes();
    }
    // eslint-disable-next-line
  }, [serieSelected, setSerieSelected]);

  //use efect para el editar

  function useDeepCompareMemoize(value) {
    const ref = React.useRef();
    if (!deepEqual(value, ref.current)) {
      ref.current = value;
    }
    return ref.current;
  }

  function deepEqual(a, b) {
    if (a === b) return true;
    if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) return false;
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length) return false;
    for (let i = 0; i < keysA.length; i++) {
      if (!Object.prototype.hasOwnProperty.call(b, keysA[i])) return false;
      if (!deepEqual(a[keysA[i]], b[keysA[i]])) return false;
    }
    return true;
  }

  const processSeriesData = useCallback(async (uniqSerie, flatPerm, currentPage) => {
    const finalData = [];
    const finalTypes = [];
    let counter = 0;
    let i = (currentPage - 1) * 4;

    for (i; i < uniqSerie.length && counter < 4; i++) {
      const serie = uniqSerie[i].serie;
      const area = uniqSerie[i].area;
      const resPermBySeries = flatPerm.filter(
        (perm) => perm.SERIEIDX === serie && perm.AREAIDXX === area
      );
      const permisosByType = resPermBySeries.reduce((acc, row) => {
        acc[row.TIPOIDXX] = {
          CARGUEXX: row.CARGUEXX,
          DELETEXX: row.DELETEXX,
          VERXXXXX: row.VERXXXXX,
          EDITXXXX: row.EDITXXXX,
          DOWNLOAD: row.DOWNLOAD,
          PRINTXXX: row.PRINTXXX
        };
        return acc;
      }, {});

      finalData.push({
        AREAIDXX: area,
        SERIEIDX: serie,
        TIPOIDXX: permisosByType,
      });
      const typesResponse = await findTypesBySerie({
        EMPNITXX: resPermBySeries[0].EMPNITXX,
        AREAIDXX: area,
        SERIEIDX: serie,
        TIPOIDXX: "null",
      });
      finalTypes.push(typesResponse.data.data);
      counter++;
    }

    if (counter === 4 || i === uniqSerie.length) {
      setShowAlert(false);
      setregActu(regActu + counter);
    }
    setDataRowSelct(finalData);
    setDataRowPrimer(finalData);
    setSeriesByKey(finalTypes);
    setTypesByKey(finalTypes);
  }, [findTypesBySerie, regActu]);

  const fetchData = useCallback(
    async (Type) => {
      try {
        setShowAlert(true);
        const allPermResponse = await findPermByUser({
          USRIDXXX: memoizedRow.USRIDXXX,
          EMPNITXX: memoizedRow.EMPNITXX,
          page: 1,
        });
        const permC = allPermResponse.data.data.data;
        const allPerm = [permC];
        for (let i = 2; i <= allPermResponse.data.data.last_page; i++) {
          const nextPageResponse = await findPermByUser({
            USRIDXXX: memoizedRow.USRIDXXX,
            EMPNITXX: memoizedRow.EMPNITXX,
            page: i,
          });
          allPerm.push(nextPageResponse.data.data.data);
        }
        const flatPerm = allPerm.flat();
        setFlatPerm(flatPerm);
        const seenCombinations = {};
        const uniqSerie = [];
        flatPerm.forEach(perm => {
          const key = `${perm.SERIEIDX}_${perm.AREAIDXX}`;
          if (!seenCombinations[key]) {
            seenCombinations[key] = true;
            uniqSerie.push({ serie: perm.SERIEIDX, area: perm.AREAIDXX });
          }
        });
        setTotalAreas(uniqSerie);
        const pages = Math.ceil(uniqSerie.length / 4);
        setTotalPages(pages);
        await processSeriesData(uniqSerie, flatPerm, Type);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [findPermByUser, processSeriesData, memoizedRow]
  );

  useEffect(() => {
    if (row !== undefined && !initialized.current) {
      setCompanySelected(row.EMPNITXX);
      setEdit(true);
      setUserSelc(`${row.USRIDXXX} - ${row.USRNOMXX}`);
      fetchData(1);
      initialized.current = true;
    }
  }, [row, fetchData]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setShowAlert(true);
    if(dataRowPrimer !== dataRowSelct){
      if(!visual){
        saveData(false , pageNumber);
      }else{
        processSeriesData(totalAreas,flatPerm,pageNumber);
      }
    }else{
      processSeriesData(totalAreas,flatPerm,pageNumber);
    }
  };
  const renderPageNumbers = () => {
    const pagesToShow = 2;
    const pages = [];
    if (currentPage > 1) {
      pages.push(
        <div
          key="first"
          className="mx-1 bg-light rounded-pill px-2 py-1 cursor-pointer"
          onClick={() => handlePageChange(1)}
        >
          Primera
        </div>
      );
      pages.push(
        <div
          key="prev"
          className="mx-1 bg-light rounded-pill px-2 py-1 cursor-pointer"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Anterior
        </div>
      );
    }

    for (let i = currentPage - pagesToShow; i <= currentPage + pagesToShow; i++) {
      if (i > 0 && i <= totalPages) {
        pages.push(
          <div
            key={i}
            className={`mx-1 ${
              i === currentPage ? 'bg-warning text-white' : 'bg-light'
            } rounded-pill px-2 py-1 cursor-pointer`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </div>
        );
      }
    }

    if (currentPage < totalPages) {
      pages.push(
        <div
          key="next"
          className="mx-1 bg-light rounded-pill px-2 py-1 cursor-pointer"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Siguiente
        </div>
      );
      pages.push(
        <div
          key="last"
          className="mx-1 bg-light rounded-pill px-2 py-1 cursor-pointer"
          onClick={() => handlePageChange(totalPages)}
        >
          Última
        </div>
      );
    }

    return pages;
  };

  const LoadingAlert = ({ show }) => {
    return (
      <>
        {show && (
          <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 9998 }}>
            <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark" style={{ opacity: 0.5 }}></div>
            <div className="position-relative">
              <div className="alert bg-warning text-white d-flex align-items-center" role="alert" style={{ zIndex: 9999 }}>
                <div className="spinner-border text-secondary me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <strong>Espere un momento...</strong>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const verifyExist =  async (cUser) => {
    setShowAlert(true);
    const allPermResponse = await findPermByUser({USRIDXXX: cUser, EMPNITXX: companySelected, page: 1 });
    if(allPermResponse.data.data.total !== 0){
      dispatch(setShowMessage({ message: "El usuario ya cuenta con permisos asignados favor ingresar a EDITAR"}));
      onClose(false);
    }else{
      setShowAlert(false);
      setUserSelected(cUser);
      setUserSelc(`${cUser}`);
    }
  }

  const addRow = () => {
    setregDes(1);
    setDataRowSelct((prevdata) => prevdata.concat({"AREAIDXX": "", "SERIEIDX": "", "TIPOIDXX": [], "regDes": 1}));
    setSeriesByKey((prevSeries) => [ ...prevSeries, []]);
    setTypesByKey((prevTyps) => [ ...prevTyps, []]);
  }
  const delRow = async (nRow) => {
    setShowAlert(true);
    const rowToDelete = dataRowSelct[nRow];
      const { AREAIDXX, SERIEIDX, TIPOIDXX, regDes } = rowToDelete;
      if(!regDes){
        for (let key in TIPOIDXX) {
          var usuario = usuarioSelc;
          if (usuario.includes('-')) {
            let userSel = usuario.split('-');
            usuario = userSel[0].trim();
          }
          let onSave = await InaStoreData(usuario,
            companySelected,
            AREAIDXX,
            SERIEIDX,
            key);
          if (onSave) {
            setDataRowSelct(dataRowSelct.filter((row, ind) => ind !== nRow));
            setSeriesByKey(seriesByKey.filter((row, key) => key !== nRow));
            setTypesByKey(typesByKey.filter((row, ind) => ind !== nRow));
            setShowAlert(false);
            fetchData(currentPage);
          }
        }
      }else{
        setDataRowSelct(dataRowSelct.filter((row, ind) => ind !== nRow));
        setSeriesByKey(seriesByKey.filter((row, key) => key !== nRow));
        setTypesByKey(typesByKey.filter((row, ind) => ind !== nRow));
        setShowAlert(false);
      }
  };

  const addPermType = (key, e) => {
    let val = "NO";
    if (e.target.checked) {
      val = "SI";
    }
    let camp = e.target.id.split("_");
    setDataRowSelct(dataRowSelct.map((row, ind) => {
      if (ind === key ) {
        let prs = {};
        if (camp[0] === "ALL") {
          let allTyps = typesByKey[key].map((op) => {
            return {[op.TIPOIDXX] : {...row.TIPOIDXX[op.TIPOIDXX], [camp[1]]: val}}
          })
          let arrrTyps = typesByKey[key].map((i) => {
            return i.TIPOIDXX
          })

          let finAll = [];
          for (let i = 0; i < arrrTyps.length; i++) {
            finAll[arrrTyps[i]] = allTyps[i][arrrTyps[i]];
          }
          return { ...row,  TIPOIDXX: finAll};
        } else {
          if (row.TIPOIDXX[camp[0]] !== undefined) {
            if (camp[1] !== "VERXXXXX" && val === "SI") {
              prs = {...row.TIPOIDXX[camp[0]], [camp[1]]: val, VERXXXXX: "SI"}
            } else {
              prs = {...row.TIPOIDXX[camp[0]], [camp[1]]: val}
            }
          } else {
            if (camp[1] !== "VERXXXXX" && val === "SI") {
              prs = {[camp[1]]: val, VERXXXXX: "SI"}
            } else {
              prs = {[camp[1]]: val}
            }
          }
          let res = {...row.TIPOIDXX, [camp[0]]: prs}
          return { ...row,  TIPOIDXX: res};
        }
      } else {
        return row;
      }
    }))
  }

  const saveStoreData = async(
    usuarioSelc,
    EMPNITXX,
    AREAIDXX,
    SERIEIDX,
    TIPOIDXX,
    CARGUEXX,
    VERXXXXX,
    DELETEXX,
    EDITXXXX,
    DOWNLOAD,
    PRINTXXX
    ) => {
    try {
      const response = await setSaveData({
        USRIDXXX: usuarioSelc,
        EMPNITXX: EMPNITXX,
        AREAIDXX: AREAIDXX,
        SERIEIDX: SERIEIDX,
        TIPOIDXX: TIPOIDXX,
        CARGUEXX: CARGUEXX,
        VERXXXXX: VERXXXXX,
        DELETEXX: DELETEXX,
        EDITXXXX: EDITXXXX,
        DOWNLOAD,
        PRINTXXX
      }).unwrap();
      return !!response.data;
    } catch (error) {
      console.error("Error al guardar datos:", error);
      return false;
    }
  }

  const InaStoreData = async(usuarioSelc, EMPNITXX, AREAIDXX, SERIEIDX, TIPOIDXX) => {
    let bReturn = false;
    await setInaData({
      TIPOOPEX: "INDIVIDUAL",
      USRIDXXX: usuarioSelc,
      AREAIDXX: AREAIDXX,
      SERIEIDX: SERIEIDX,
      TIPOIDXX: TIPOIDXX,
      EMPNITXX: EMPNITXX
    })
    .unwrap().then((response) => {
      if (response.data) {
        bReturn = true;
      }
    })
    return bReturn;
  }

  const saveData = async (typeClose, cCurrent) => {
    setLoadsave(true);
    setShowAlert(true);
    let onSaveAll = true;
    let nkey = 0;
    var bInactivate = false;
    var cMessageI = "";
    let EMPNITXX = [], AREAIDXX = [], SERIEIDX = [], TIPOIDXX = [], CARGUEXX = [], VERXXXXX = [], DELETEXX = [], EDITXXXX = [];
    EMPNITXX[nkey] = []; AREAIDXX[nkey] = []; SERIEIDX[nkey] = []; TIPOIDXX[nkey] = []; CARGUEXX[nkey] = []; VERXXXXX[nkey] = []; DELETEXX[nkey] = []; EDITXXXX[nkey] = [];

    let EMPNITIN = [], AREAIDIN = [], SERIEIIN = [], TIPOIDIN = [];
    EMPNITIN[nkey] = []; AREAIDIN[nkey] = []; SERIEIIN[nkey] = []; TIPOIDIN[nkey] = [];
    let DOWNLOAD = {[nkey]: []};
    let PRINTXXX = {[nkey]: []};

    dataRowSelct.map(rows => {
      Object.entries(rows.TIPOIDXX).map((tipes, key) => {
        if (EMPNITXX[nkey].length === 30) {
          nkey = nkey + 1;
          EMPNITXX[nkey] = []; AREAIDXX[nkey] = []; SERIEIDX[nkey] = []; TIPOIDXX[nkey] = [];
          EMPNITIN[nkey] = []; AREAIDIN[nkey] = []; SERIEIIN[nkey] = []; TIPOIDIN[nkey] = [];
          CARGUEXX[nkey] = []; VERXXXXX[nkey] = []; DELETEXX[nkey] = []; EDITXXXX[nkey] = [];
          DOWNLOAD[nkey] = [];
          PRINTXXX[nkey] = [];
        }
        let cargue = tipes[1].CARGUEXX !== undefined ? tipes[1].CARGUEXX : "NO";
        let ver = tipes[1].VERXXXXX !== undefined ? tipes[1].VERXXXXX : "NO";
        let del = tipes[1].DELETEXX !== undefined ? tipes[1].DELETEXX : "NO";
        let edit = tipes[1].EDITXXXX !== undefined ? tipes[1].EDITXXXX : "NO";
        let download = tipes[1].DOWNLOAD ?? "NO";
        let print = tipes[1].PRINTXXX ?? "NO";
        let bCargue = true;
        if(cargue == "NO" && ver == "NO" && del == "NO" && edit == "NO" && download === "NO" && print === "NO") {
          bInactivate = true;
          bCargue = false;
          EMPNITIN[nkey].push(companySelected);
          AREAIDIN[nkey].push(rows.AREAIDXX);
          SERIEIIN[nkey].push(rows.SERIEIDX);
          TIPOIDIN[nkey].push(tipes[0]);
        }
        if(bCargue){
          EMPNITXX[nkey].push(companySelected);
          AREAIDXX[nkey].push(rows.AREAIDXX);
          SERIEIDX[nkey].push(rows.SERIEIDX);
          TIPOIDXX[nkey].push(tipes[0]);
          CARGUEXX[nkey].push(cargue);
          VERXXXXX[nkey].push(ver);
          DELETEXX[nkey].push(del);
          EDITXXXX[nkey].push(edit);
          DOWNLOAD[nkey].push(download);
          PRINTXXX[nkey].push(print);
        }
        return null;
      });
      return null;
    });
    if(bInactivate){
      var cInactivate = false;
      for (let i = 0; i < EMPNITIN[0].length; i++) {
        let onSave = await InaStoreData(row.USRIDXXX,
          EMPNITIN[0][i],
          AREAIDIN[0][i],
          SERIEIIN[0][i],
          TIPOIDIN[0][i]);
        if (onSave) {
          cMessageI = "Modificaciones exitosas de los permisos, verifique";
          cInactivate = true;
        }
      }
    }
    if (edit) {
      let saveEdit = true;

      const savePromises = [];
      for (let i = 0; i < EMPNITXX.length; i++) {
        if (AREAIDXX[i] !== "" && SERIEIDX[i] !== "" && TIPOIDXX[i] !== "") {
          savePromises.push(
            saveStoreData(row.USRIDXXX,
              EMPNITXX[i],
              AREAIDXX[i],
              SERIEIDX[i],
              TIPOIDXX[i],
              CARGUEXX[i],
              VERXXXXX[i],
              DELETEXX[i],
              EDITXXXX[i],
              DOWNLOAD[i],
              PRINTXXX[i])
          );
        }
      }
      const results = await Promise.all(savePromises);
      onSaveAll = results.every(result => result);
      setLoadsave(false);
      if (saveEdit) {
        dispatch(setShowMessage({ message: "Modificaciones exitosas de los permisos, verifique"}));
        if(bInactivate){
          dispatchUser(apiSlice.util.invalidateTags(["reloadTable"]));
        }
        if(typeClose){
          onClose(false);
        }else{
          fetchData(cCurrent);
        }
      }
    } else {
      const savePromises = [];

      for (let i = 0; i < EMPNITXX.length; i++) {
        if (AREAIDXX[i] !== "" && SERIEIDX[i] !== "" && TIPOIDXX[i] !== "") {
          savePromises.push(
            saveStoreData(usuarioSelc,
              EMPNITXX[i],
              AREAIDXX[i],
              SERIEIDX[i],
              TIPOIDXX[i],
              CARGUEXX[i],
              VERXXXXX[i],
              DELETEXX[i],
              EDITXXXX[i],
              DOWNLOAD[i],
              PRINTXXX[i])
          );
        }
      }
      const results = await Promise.all(savePromises);
      onSaveAll = results.every(result => result);
      setLoadsave(false);
      if (onSaveAll) {
        if(typeClose){
          onClose(false);
        }
        dispatch(setShowMessage({ message: "Cargue exitoso de los permisos"}));
        dispatchUser(apiSlice.util.invalidateTags(["reloadTable"]));
        }
      }
    if(cInactivate){
      dispatch(setShowMessage({ message: cMessageI}));
    }
  }

  return (
    <Container>
      <Form className="mt-4" >
      <LoadingAlert show={showAlert} />
        <Row md={12} className="p-4">
          <Form.Group as={Col} md={4}>
            <Form.Label>Empresa:</Form.Label>
            <Form.Select
              onChange={(e) => setCompanySelected(e.target.value)}
              value={companySelected}
            >
              {
                isLoadEmpr ? (
                  <option value="">[SELECCIONE]</option>
                ) : (
                  <>
                    <option value="">[SELECCIONE]</option>
                    {
                      dataempresas?.data?.map((empr, ind) => {
                        return(
                            <option key={ind} value={empr.EMPNITXX}>{empr.EMPDESXX}</option>
                        )
                      })
                    }
                  </>
                )
              }
            </Form.Select>
          </Form.Group>
          {
            !edit && (
              <Form.Group as={Col} md={4}>
                <Form.Label>Usuario:</Form.Label>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="userid"
                  isLoading={loaduser}
                  minLength={3}
                  onSearch={searchUser}
                  options={options}
                  placeholder="Busqueda Por Usuario"
                  renderMenuItemChildren = {(options) => (
                    <span>{options.label}</span>
                  )}
                  onChange={(select) => {
                    if (select.length > 0) {
                      verifyExist(select[0].id);
                    }
                  }}
                />
              </Form.Group>
            )
          }
        </Row>
        {
          usuarioSelc !== "" && (
            <>
              <Row md={12} className="p-4">
                <Badge as={Col} md={3} className="p-3 w-50 " bg="warning">PERMISO USUARIO : {usuarioSelc}</Badge>
              </Row>
              {
                dataRowSelct.map((row, key) => {
                  let isDisabled = row.regDes === 0 || row.regDes === undefined ;
                  if(edit === false){
                    isDisabled  = false;
                  }
                  return (
                    <Row key={key} md={12} className="p-0">
                      <Col md={3}><Badge className="p-2 w-100" bg="warning">Proceso</Badge>
                      <Form.Group >
                        <Form.Select
                          onChange={(e) => {
                            setAreaSelected(e.target.value);
                            setTrigger(!trigger);
                            setKeyRow(key);
                          }}
                          value={dataRowSelct[key].AREAIDXX}
                          disabled = {isDisabled}
                        >
                          {
                            isLoadingAreas || isUninitializedAreas ? (
                              <option value="">[SELECCIONE]</option>
                            ) : (
                              <>
                                <option value="">[SELECCIONE]</option>
                                {
                                  areas?.data?.map((area, ind) => {
                                    return(
                                        <option key={ind} value={area.AREAIDXX}>{area.AREADESX}</option>
                                    )
                                  })
                                }
                              </>
                            )
                          }
                        </Form.Select>
                      </Form.Group>
                      </Col>
                      <Col md={3}><Badge className="p-2 w-100" bg="warning">Serie</Badge>
                      <Form.Group >
                        <Form.Select
                          onChange={(e) => {
                            setSerieSelected(e.target.value)
                            setKeyRow(key)
                          }}
                          value={dataRowSelct[key].SERIEIDX}
                          disabled = {isDisabled}
                        >
                          {
                            <>
                              <option value="">[SELECCIONE]</option>
                              {
                                seriesByKey[key]?.map((serie, ind) => {
                                  return(
                                      <option key={ind} value={serie.SERIEIDX}>{serie.SERIEDES}</option>
                                  )
                                })
                              }
                            </>
                          }
                        </Form.Select>
                      </Form.Group>
                      </Col>
                      <Col md={5}><Badge className="p-2 w-100" bg="warning">Tipo</Badge>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>[SELECCIONE]</Accordion.Header>
                          <Accordion.Body>
                            <Row md={12}>
                              <Col md={6}><Form.Label>Tipos</Form.Label></Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Cargue</Tooltip>}><span className="bi bi-upload" /></OverlayTrigger></Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Visualizar</Tooltip>}><span className="bi bi-eye-fill" /></OverlayTrigger></Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}><span className="bi bi-pencil-square" /></OverlayTrigger></Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Eliminar</Tooltip>}><span className="bi bi-trash" /></OverlayTrigger></Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar</Tooltip>}><span className="bi bi-download" /></OverlayTrigger></Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Imprimir</Tooltip>}><span className="bi bi-printer" /></OverlayTrigger></Col>
                            </Row>
                            <Row md={12}>
                              <Col md={6}><Form.Label></Form.Label></Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Seleccionar Todos</Tooltip>}>
                                <Form.Check type="checkbox" id={'ALL_CARGUEXX'} onChange={(e) => addPermType(key, e)} /></OverlayTrigger>
                              </Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Seleccionar Todos</Tooltip>}>
                                <Form.Check type="checkbox" id={'ALL_VERXXXXX'} onChange={(e) => addPermType(key, e)} /></OverlayTrigger>
                              </Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Seleccionar Todos</Tooltip>}>
                                <Form.Check type="checkbox" id={'ALL_EDITXXXX'} onChange={(e) => addPermType(key, e)} /></OverlayTrigger>
                              </Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Seleccionar Todos</Tooltip>}>
                                <Form.Check type="checkbox" id={'ALL_DELETEXX'} onChange={(e) => addPermType(key, e)} /></OverlayTrigger>
                              </Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Seleccionar Todos</Tooltip>}>
                                <Form.Check type="checkbox" id={'ALL_DOWNLOAD'} onChange={(e) => addPermType(key, e)} /></OverlayTrigger>
                              </Col>
                              <Col md={1}><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Seleccionar Todos</Tooltip>}>
                                <Form.Check type="checkbox" id={'ALL_PRINTXXX'} onChange={(e) => addPermType(key, e)} /></OverlayTrigger>
                              </Col>
                            </Row>
                              {
                              isLoadingTypes || isUninitializedTypes ? (
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                              ) : (
                                typesByKey[key]?.map((typ, ind) => {
                                  return (
                                    <Row key={ind} md={12}>
                                      <Col md={6}>{typ.TIPODESX}</Col>
                                      <Col md={1}>
                                        <Form.Check
                                          type="checkbox"
                                          id={`${typ.TIPOIDXX}_CARGUEXX`}
                                          value={`${typ.TIPOIDXX}_C`}
                                          onChange={(e) => addPermType(key, e)}
                                          checked={ row.TIPOIDXX[`${typ.TIPOIDXX}`]?.CARGUEXX === "SI" ? true : false }
                                        />
                                      </Col>
                                      <Col md={1}>
                                        <Form.Check
                                          type="checkbox"
                                          id={`${typ.TIPOIDXX}_VERXXXXX`}
                                          value={`${typ.TIPOIDXX}_V`}
                                          onChange={(e) => addPermType(key, e)}
                                          checked={ row.TIPOIDXX[`${typ.TIPOIDXX}`]?.VERXXXXX === "SI" ? true : false }
                                        />
                                      </Col>
                                      <Col md={1}>
                                        <Form.Check
                                          type="checkbox"
                                          id={`${typ.TIPOIDXX}_EDITXXXX`}
                                          value={`${typ.TIPOIDXX}_E`}
                                          onChange={(e) => addPermType(key, e)}
                                          checked={ row.TIPOIDXX[`${typ.TIPOIDXX}`]?.EDITXXXX === "SI" ? true : false }
                                        />
                                      </Col>
                                      <Col md={1}>
                                        <Form.Check
                                          type="checkbox"
                                          id={`${typ.TIPOIDXX}_DELETEXX`}
                                          value={`${typ.TIPOIDXX}_D`}
                                          onChange={(e) => addPermType(key, e)}
                                          checked={ row.TIPOIDXX[`${typ.TIPOIDXX}`]?.DELETEXX === "SI" ? true : false }
                                        />
                                      </Col>
                                      <Col md={1}>
                                        <Form.Check
                                          type="checkbox"
                                          id={`${typ.TIPOIDXX}_DOWNLOAD`}
                                          value={`${typ.TIPOIDXX}_DD`}
                                          onChange={(e) => addPermType(key, e)}
                                          checked={ row.TIPOIDXX[`${typ.TIPOIDXX}`]?.DOWNLOAD === "SI" ? true : false }
                                        />
                                      </Col>
                                      <Col md={1}>
                                        <Form.Check
                                          type="checkbox"
                                          id={`${typ.TIPOIDXX}_PRINTXXX`}
                                          value={`${typ.TIPOIDXX}_P`}
                                          onChange={(e) => addPermType(key, e)}
                                          checked={ row.TIPOIDXX[`${typ.TIPOIDXX}`]?.PRINTXXX === "SI" ? true : false }
                                        />
                                      </Col>
                                    </Row>
                                  )
                                })
                              )
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      </Col>
                      {
                        component !== "VISUALIZAR" && (
                        <Col md={1}>
                          <span className="bi bi-plus-circle-fill p-2" style={{ color: "#fba500", fontsize: "1.5em" }}
                          onClick={() => addRow()}/>
                          { dataRowSelct.length > 1 && (
                            <span className="bi bi-x-circle-fill" style={{ color: "#bdb9b3", fontsize: "1.5em"}}
                              onClick={() => delRow(key)}/>
                            )
                          }
                        </Col>
                        )
                      }
                    </Row>
                  )
                })
              }
              {totalPages > 1 && (
                  <div className="d-flex justify-content-center mt-2">
                    {renderPageNumbers()}
                  </div>
              )}
            </>
          )
        }
        <Modal.Footer>
          <Container className="d-grid gap-2 d-md-flex justify-content-md-end">
          </Container>
          <Container className="d-grid gap-2 d-md-flex justify-content-md-end">
            {
              (visual === undefined && !loadSave) && (
                <>
                  <Button
                    onClick={() => saveData(true, "1")}
                    >
                    Guardar
                  </Button>
                </>
              )
            }
            {
              loadSave && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )
            }
          </Container>
        </Modal.Footer>
      </Form>
    </Container>
  );
}

export default Asigperu;
