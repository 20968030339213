export const columnsTracking = [
  { id: "idCargo", title: "ID", width: "5%", position: "CARIDXXX" },
  { id: "idCardesc", title: "Descripción Cargo", width: "25%", position: "CARDESXX" },
  { id: "idEmpres", title: "Empresa", width: "20%", position: "EMPDESXX" },
  { id: "idModificado", title: "Modificado Por", width: "20%", position: "USRNOMXX" },
  { id: "idFechaMod", title: "Fecha de Modificación", width: "15%", position: "REGSTAMP" },
  ];

  export const buttons = [
    { id: "idEliminarCargo", class: "bi bi-trash", title: "Eliminar" },
    { id: "idVerPermisos", class: "bi bi-eye-fill", title: "Ver" },
    { id: "idEditarPermisos", class: "bi bi-pencil-square", title: "Editar" },
    { id: "idClonarPermisos", class: "bi bi-files", title: "Clonar" },
  ];

