import { createSlice } from "@reduxjs/toolkit";

const confirmxxSlice = createSlice({
  name: "confirm",
  initialState: {
    showConfirm: false,
    message: "",
  },
  reducers: {
    setShowMessage: (state, action) => {
      if (action.payload.message !== "") {
        state.message = action.payload.message;
        state.showConfirm = true;
      } else {
        state.message = "";
        state.showConfirm = false;
      }
    },
  },
});

export const { setShowMessage } = confirmxxSlice.actions;

export default confirmxxSlice.reducer;

export const getShowConfirm = (state) => {
  return state.confirm.showConfirm;
};

export const getMessage = (state) => {
  return state.confirm.message;
};
