import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { signOut } from "./authSlice/authSlice";
import { setShowErrors, setObjectErrors } from "./errorSlice/errorSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_GENIO_ENDPOINT,
  mode: "cors",
  timeout: process.env.REACT_APP_GENIO_TIMEOUT,
  credentials: "include",
  prepareHeaders: (Headers, { getState }) => {
    const token = getState().persistedReducer.token;
    Headers.set("Accept", "application/json");
    if (token) {
      Headers.set("Authorization", `Bearer ${token}`);
    }
    return Headers;
  },
});

const baseQueryWithLogOut = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  switch (result?.error?.status) {
    case 401:
      api.dispatch(signOut());
      break;
    case 403:
    case 422:
    case 500:
      api.dispatch(setShowErrors(true));
      api.dispatch(
        setObjectErrors(result?.error?.data?.errors ? result?.error?.data?.errors : ["Se presentaron problemas al realizar la peticion"])
      );
      break;
    default:
      break;
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithLogOut,
  endpoints: (builder) => ({}),
  tagTypes: ["reloadTable"],
});
