import { apiSlice } from "../apiSlice";

export const fileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    documentaryPackage: builder.mutation({
      query: ({ filters }) => ({
        url: "/paquetesdocumentales?" + filters,
        method: "GET",
      }),
    }),
    createDocumentaryPackage: builder.mutation({
      query: (parameters) => ({
        url: "/paquetesdocumentales",
        method: "POST",
        body: parameters,
      }),
    }),
    createDocumentaryPackageHeader: builder.mutation({
      query: (parameters) => ({
        url: "/paquetesdocumentalescabecera",
        method: "POST",
        body: parameters,
      }),
    }),
    updateDocumentaryPackagePart: builder.mutation({
      query: ({ path, body }) => ({
        url: "/paquetesdocumentalespartes/" + path.IDARCHXX + "/" + path.IDPARTXX,
        method: "PUT",
        body: body,
      }),
    }),
    createPackagePresigned: builder.mutation({
      query: (parameters) => ({
        url: "/paquetedocumentalperfirmados",
        method: "POST",
        body: parameters,
      }),
    }),
    confirmPackagePresigned: builder.mutation({
      query: ({ path, body }) => ({
        url: "/paquetedocumentalconfirmacion/" + path.IDARCHXX,
        method: "PUT",
        body: body,
      }),
    }),
  }),
});

export const { useDocumentaryPackageMutation } = fileApiSlice;
export const { useCreateDocumentaryPackageMutation } = fileApiSlice;
export const { useCreateDocumentaryPackageHeaderMutation } = fileApiSlice;
export const { useUpdateDocumentaryPackagePartMutation } = fileApiSlice;
export const { useCreatePackagePresignedMutation } = fileApiSlice;
export const { useConfirmPackagePresignedMutation } = fileApiSlice;
