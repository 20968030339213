import React, { useState } from "react";

import { Form, Row, Col, InputGroup, Button, Spinner } from "react-bootstrap";

//bootrap icon
import { EyeFill } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";

import { getUser, reset } from "../../libs/redux/slices/authSlice/authSlice";
import { useRestorePasswordMutation } from "../../libs/redux/slices/authSlice/authApiSlice";

import { useNavigate } from "react-router-dom";

import { setShowErrors, setObjectErrors } from "../../libs/redux/slices/errorSlice/errorSlice";

const Changeps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [restorePassword, { isLoading }] = useRestorePasswordMutation();
  const USRIDXXX = useSelector(getUser);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const changePassword = async (e) => {
    e.preventDefault();
    if (pass1 === pass2) {
      await restorePassword({ USRIDXXX, pass1 })
        .then((response) => {
          console.log(response?.data?.data);
          if (response?.data?.data) {
            dispatch(reset());
            navigate("/login");
          }
        })
        .catch((rejected) => {
          console.log("deberia entrar por aqui de rechazado", rejected);
        });
    } else {
      dispatch(setShowErrors(true));
      dispatch(setObjectErrors({ message: "Las contraseñas no coinciden" }));
    }
  };
  return isLoading ? (
    <Spinner animation="grow" />
  ) : (
    <Form className="text-start w-100 mx-auto border border-5 classFromLogin" onSubmit={changePassword}>
      <Row className="mt-5">
        <Col className="ms-5">
          <Form.Label>Nueva Contraseña</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="ms-5 me-5 w-auto">
            <Form.Control
              type={!showPassword1 ? "password" : "text"}
              placeholder="Contraseña"
              aria-label="idPassword"
              aria-describedby="idPassword"
              value={pass1}
              onChange={(e) => setPass1(e.target.value)}
              minLength={8}
              required
            />
            <InputGroup.Text onClick={() => setShowPassword1(!showPassword1)} style={{ cursor: "pointer" }}>
              <EyeFill />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="ms-5">
          <Form.Label>Confirmar Contraseña</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="ms-5 me-5 w-auto">
            <Form.Control
              type={!showPassword2 ? "password" : "text"}
              placeholder="Contraseña"
              aria-label="idPassword"
              aria-describedby="idPassword"
              value={pass2}
              onChange={(e) => setPass2(e.target.value)}
              minLength={8}
              required
            />
            <InputGroup.Text onClick={() => setShowPassword2(!showPassword2)} style={{ cursor: "pointer" }}>
              <EyeFill />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Form.Group className="mb-5 mt-5 text-center">
        <Button type="submit">CAMBIAR CONTRASEÑA</Button>
      </Form.Group>
    </Form>
  );
};

export default Changeps;
