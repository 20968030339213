import React from "react";
//Bootstrap
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Loginxxx from "../components/Loginxxx";
import Changeps from "../components/Changeps";

//Redux
import { getisChangePass } from "../libs/redux/slices/authSlice/authSlice";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const bChangePass = useSelector(getisChangePass);
  return (
    <Container fluid className="classContainer vh-100">
      <Row className="justify-content-md-center mb-5">
        <Col xxl={4} xl={6} md={10} className="align-self-center mt-5">
          <Image
            src="https://gestordocumental.repremundo.com.co/sites/default/files/Genio%20documental1-33.png"
            width="95%"
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-5">
        <Col xxl={4} xl={6} md={10} className="align-self-center text-center">
          <h1>Iniciar Sesión</h1>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xxl={4} xl={6} md={10} className="align-self-center">
          {bChangePass ? <Changeps /> : <Loginxxx />}
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
