import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = "./pdf.worker.min.js";

export const countPages = (file) => {
  const obejctFile = new FileReader();
  return new Promise((resolve, reject) => {
    obejctFile.onload = function () {
      const fileArray = new Uint8Array(obejctFile.result);
      pdfjs.getDocument(fileArray)
        .promise.then(function (pdf) {
          const numPages = pdf.numPages;
          resolve(numPages);
        })
        .catch(function (error) {
          reject(error);
        });
    };
    obejctFile.onerror = function (error) {
      reject(error);
    };
    obejctFile.readAsArrayBuffer(file);
  });
};
