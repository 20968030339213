import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import Navbarxx from "../components/Navbarxx";
import Filtersx from "../components/Filtersx";
import Tabsperc from "../components/Tabsperc/Tabsperc";
import Asigperm from "../components/Asigperm";
import Clonperm from "../components/Asigperm/Clonperm";
import { useState } from "react";

const PercargoPage = () => {
  const [show, setShow] = useState(false);
  const [component, setComponent] = useState("");
  const [row, setRow] = useState({});
  let oComponet;
  switch (component) {
    case "ASIGNAR":
      oComponet = <Asigperm onClose={setShow} component={component} />
      break;
    case "EDITAR":
      oComponet = <Asigperm onClose={setShow} component={component} row={row}/>
      break;
    case "VISUALIZAR":
      oComponet = <Asigperm onClose={setShow} component={component} row={row} visual={true}/>
      break;
    case "CLONAR":
      oComponet = <Clonperm onClose={setShow} row={row} />
      break;
    default:
      break;
  }
  return (
    <>
      {
        component === "CLONAR" ? (
          <Modal show={show} onHide={() => setShow(false)} className={"modal-lg"}>
            <Modal.Header closeButton>
              <Modal.Title>CLONAR PERMISOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>{oComponet}</Modal.Body>
          </Modal>
        ) : component === "VISUALIZAR" ? (
          <Modal show={show} onHide={() => setShow(false)} className={"modal-xl"}>
            <Modal.Header closeButton>
              <Modal.Title>PERMISOS ASIGNADOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>{oComponet}</Modal.Body>
          </Modal>
        ) : component === "EDITAR" ? (
          <Modal show={show} onHide={() => setShow(false)} className={"modal-xl"}>
            <Modal.Header closeButton>
              <Modal.Title>EDITAR PERMISOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>{oComponet}</Modal.Body>
          </Modal>
        ) : (
          <Modal show={show} onHide={() => setShow(false)} className={"modal-xl"}>
            <Modal.Header closeButton>
              <Modal.Title>ASIGNAR PERMISOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>{oComponet}</Modal.Body>
          </Modal>
        )
      }
      <Navbarxx path={"percargo"} />
      <Container>
        <Row className="justify-content-md-center">
          <Col xxl={4} xl={6} md={10} className="align-self-center mt-1">
            <Image
              src="https://gestordocumental.repremundo.com.co/sites/default/files/Genio%20documental1-33.png"
              width="95%"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Button
              className="w-20"
              onClick={() => {
                setComponent("ASIGNAR");
                setShow(true);
              }}
            >
              Asignar Permiso Cargo
            </Button>
          </Col>
        </Row>
        <Row>
          <Filtersx />
        </Row>
        <Row className="mt-3">
          <Tabsperc setComponent={setComponent} setShow={setShow} setRow={setRow}/>
        </Row>
      </Container>


    </>
  )
}

export default PercargoPage;
