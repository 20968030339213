import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
//Bootstrap Style
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

//redux
import { Provider } from "react-redux";
import { persistor, store } from "./libs/redux/store/store";
import { PersistGate } from "redux-persist/integration/react";

import Errorsx from "../src/components/Errorsx";
import Confirxx from "./components/Confirxx";
import Direuplo from "./components/Direuplo";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <Errorsx />
      <Confirxx />
      <Direuplo />
    </PersistGate>
  </Provider>
);
