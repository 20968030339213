import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    dictionaryErrors: {},
    showError: false,
    objectErrors: [],
  },
  reducers: {
    setShowErrors: (state, action) => {
      state.showError = action.payload;
    },
    setObjectErrors: (state, action) => {
      state.objectErrors = action.payload;
    },
    setDictionaryErrors: (state, action) => {
      state.dictionaryErrors = action.payload.dictionaryErrors;
    },
  },
});

export const { setShowErrors, setObjectErrors, setDictionaryErrors } = errorSlice.actions;
export default errorSlice.reducer;

export const getShowError = (state) => {
  return state.error.showError;
};

export const getObjectErrors = (state) => {
  return state.error.objectErrors;
};

export const getDictionaryErrors = (state) => {
  return state.error.dictionaryErrors;
};
