import { createSlice } from "@reduxjs/toolkit";

const splitpdfSlice = createSlice({
  name: "splitpdf",
  initialState: {
    pagesSplit: {},
  },
  reducers: {
    setPagesSplit(state, action) {
      state.pagesSplit = { ...state.pagesSplit, [action.payload.index]: action.payload.listPages };
    },
    resetPagesSplit(state) {
      state.pagesSplit = {};
    },
  },
});
export const { setPagesSplit, resetPagesSplit } = splitpdfSlice.actions;
export default splitpdfSlice.reducer;
export const getPagesSplit = (state) => {
  return state.splitpdf.pagesSplit;
};
