import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useState } from "react";
import { useLazyGetCargosQuery } from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";
import { setShowMessage } from "../../libs/redux/slices/confirmxxSlice/confirmxxSlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../libs/redux/slices/apiSlice";
import { useLazyGetPermisionByCaridQuery, useSetSavePermisionCargoMutation, useInactivatePermisionByCargoMutation } from "../../libs/redux/slices/creapermSlice/creapermApiSlice";
import { useEffect } from "react";

const Clonperm = ({ onClose, row }) => {
  const [cargoSelc, setCargoSelc] = useState("");
  const [findCargos, {isLoading : loadcargos}] = useLazyGetCargosQuery();
  const [options, setOptions] = useState([]);
  const [dataClon, setDataClon] = useState([]);
  const [companySelected, setCompanySelected] = useState("");
  const [ findPermByCargo ] = useLazyGetPermisionByCaridQuery();
  const [ setSaveData ] = useSetSavePermisionCargoMutation();
  const [ setInaData ] = useInactivatePermisionByCargoMutation();
  const [ loadPermision, setLoadPermision ] = useState(false);
  const dispatch = useDispatch();
  const dispatchCargo = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const serachCarg = async (value) => {
    if (value !== "") {
      try {
        const response = await findCargos(value);
        let form = [];
        if (response?.data) {
          form = response.data.map((cargos) => {
            return { id: cargos.CARIDXXX, label: cargos.CARDESXX };
          });
        }
        setOptions(form);
      } catch (error) {
        console.error("Error fetching cargos:", error);
      }
    }
  }
  const LoadingAlert = ({ show }) => {
    return (
      <>
        {show && (
          <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 9998 }}>
            <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark" style={{ opacity: 0.5 }}></div>
            <div className="position-relative">
              <div className="alert bg-warning text-white d-flex align-items-center" role="alert" style={{ zIndex: 9999 }}>
                <div className="spinner-border text-secondary me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <strong>Espere un momento...</strong>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  useEffect(() => {
    if (row !== undefined) {
      setLoadPermision(true)
      const findPerm = async() => {
        let allPerm = [];
        let permC = await findPermByCargo({CARIDXXX: row.CARIDXXX , EMPNITXX: row.EMPNITXX, page: 1})
        allPerm = [...allPerm, ...permC.data.data.data]
        for (let i = 2; i <= permC.data.data.last_page; i++){
          let datapag = await findPermByCargo({CARIDXXX: row.CARIDXXX, page: i})
          allPerm = [...allPerm, ...datapag.data.data.data]
        }
        let findCarg = allPerm.map(ro => {
          return ro.SERIEIDX
        })
        const uniqSerie = [...new Set(findCarg)];
        const finalData = [];
        for (let i = 0; i < uniqSerie.length; i++) {
          let resPermBySeries = allPerm.filter(perms => perms.SERIEIDX === uniqSerie[i]);
          let permisosByType = resPermBySeries.map(row => {
            return {[row.TIPOIDXX] : {CARGUEXX: row.CARGUEXX, DELETEXX : row.DELETEXX, VERXXXXX: row.VERXXXXX, EDITXXXX: row.EDITXXXX}}
          })
          let arTyps = resPermBySeries.map((i) => {
            return i.TIPOIDXX
          })
          let finAllTyp = [];
          for (let i = 0; i < arTyps.length; i++) {
            finAllTyp[arTyps[i]] = permisosByType[i][arTyps[i]];
          }
          finalData.push({"AREAIDXX": resPermBySeries[0].AREAIDXX, "SERIEIDX": uniqSerie[i], "TIPOIDXX": finAllTyp})
        }
        setDataClon(finalData)
        setShowAlert(false);
        setLoadPermision(false)
      }
      setCompanySelected(row.EMPNITXX);
      findPerm();
    }
    // eslint-disable-next-line
  }, [])

  const saveStoreData = async(cargoSelc, EMPNITXX, AREAIDXX, SERIEIDX, TIPOIDXX, CARGUEXX, VERXXXXX, DELETEXX, EDITXXXX) => {
    let bReturn = false;
    await setSaveData({
      CARIDXXX: cargoSelc,
      EMPNITXX: EMPNITXX,
      AREAIDXX: AREAIDXX,
      SERIEIDX: SERIEIDX,
      TIPOIDXX: TIPOIDXX,
      CARGUEXX: CARGUEXX,
      VERXXXXX: VERXXXXX,
      DELETEXX: DELETEXX,
      EDITXXXX: EDITXXXX
    })
    .unwrap().then((response) => {
      if (response.data) {
        bReturn = true;
      }
    })
    return bReturn;
  }

  const InaStoreData = async(cargoSelc, EMPNITXX, AREAIDXX, SERIEIDX, TIPOIDXX) => {
    let bReturn = false;
    await setInaData({
      TIPOOPEX: "MASIVO",
      CARIDXXX: cargoSelc,
      AREAIDXX: AREAIDXX,
      SERIEIDX: SERIEIDX,
      TIPOIDXX: TIPOIDXX,
      EMPNITXX: EMPNITXX
    })
    .unwrap().then((response) => {
        bReturn = true;
    })
    return bReturn;
  }

  const saveClone = async () => {
    let saveClone = true;
    setLoadPermision(true);
    let nkey = 0;
    let EMPNITXX = [], AREAIDXX = [], SERIEIDX = [], TIPOIDXX = [], CARGUEXX = [], VERXXXXX = [], DELETEXX = [], EDITXXXX = [];
    EMPNITXX[nkey] = []; AREAIDXX[nkey] = []; SERIEIDX[nkey] = []; TIPOIDXX[nkey] = []; CARGUEXX[nkey] = []; VERXXXXX[nkey] = []; DELETEXX[nkey] = []; EDITXXXX[nkey] = [];
    dataClon.map(rows => {
      return Object.entries(rows.TIPOIDXX).map((tipes, key) => {
        if (EMPNITXX[nkey].length === 30) {
          nkey = nkey + 1;
          EMPNITXX[nkey] = []; AREAIDXX[nkey] = []; SERIEIDX[nkey] = []; TIPOIDXX[nkey] = []; CARGUEXX[nkey] = []; VERXXXXX[nkey] = []; DELETEXX[nkey] = []; EDITXXXX[nkey] = [];
        }
        EMPNITXX[nkey].push(companySelected);
        AREAIDXX[nkey].push(rows.AREAIDXX);
        SERIEIDX[nkey].push(rows.SERIEIDX);
        TIPOIDXX[nkey].push(tipes[0]);
        let cargue = tipes[1].CARGUEXX !== undefined ? tipes[1].CARGUEXX : "NO";
        let ver = tipes[1].VERXXXXX !== undefined ? tipes[1].VERXXXXX : "NO";
        let del = tipes[1].DELETEXX !== undefined ? tipes[1].DELETEXX : "NO";
        let edit = tipes[1].EDITXXXX !== undefined ? tipes[1].EDITXXXX : "NO";
        CARGUEXX[nkey].push(cargue);
        VERXXXXX[nkey].push(ver);
        DELETEXX[nkey].push(del);
        EDITXXXX[nkey].push(edit);
        return null;
      })
    })
    let onSavein = await InaStoreData(cargoSelc,
      companySelected,
      AREAIDXX[0][0],
      SERIEIDX[0][0],
      TIPOIDXX[0][0]);
    if (onSavein) {
      saveClone = true;
    }
    if (saveClone) {
      for (let i = 0; i < EMPNITXX.length; i++) {
        let onSave = await saveStoreData(cargoSelc,
          EMPNITXX[i],
          AREAIDXX[i],
          SERIEIDX[i],
          TIPOIDXX[i],
          CARGUEXX[i],
          VERXXXXX[i],
          DELETEXX[i],
          EDITXXXX[i]);
        if (!onSave) {
          saveClone = false;
        }
      }
    }
    setLoadPermision(false);
    if (saveClone) {
      onClose(false);
      dispatch(setShowMessage({ message: "Permisos clonados con exito, verifique"}));
      dispatchCargo(apiSlice.util.invalidateTags(["reloadTable"]));
    }
  }

  return (
    <Container>
      <LoadingAlert show={showAlert} />

      <Form className="mt-4" >
        <Row>
          <Col><Form.Label>CARGO A CLONAR:</Form.Label></Col>
          <Col><Form.Label>CARGO</Form.Label></Col>
        </Row>
        <Row>
          <Col><Form.Control type="text" value={`${row.CARIDXXX} - ${row.CARDESXX}`} readOnly /></Col>
          <Col><AsyncTypeahead
                  filterBy={() => true}
                  id="carid"
                  isLoading={loadcargos}
                  minLength={3}
                  onSearch={serachCarg}
                  options={options}
                  placeholder="Busqueda por Cargo"
                  renderMenuItemChildren = {(options) => (
                    <span>{options.id} - {options.label}</span>
                  )}
                  onChange={(select) => {
                    if (select.length > 0) {
                      setCargoSelc(select[0].id);
                    }
                  }}
                /></Col>
        </Row>
        <Modal.Footer>
          <Container className="d-grid gap-2 d-md-flex justify-content-md-end">
          </Container>
          <Container className="d-grid gap-2 d-md-flex justify-content-md-end">
            {
              loadPermision ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Button
                  onClick={() => saveClone()}
                >
                  Guardar
                </Button>
              )
            }
          </Container>
        </Modal.Footer>
      </Form>
    </Container>

  )

}

export default Clonperm;
