import React from "react";
import Form from "react-bootstrap/Form";
import Autocomx from "../Autocomx";
const Metadata = ({ GEST0004, setJsonmetx, inputValue, jsonmetx  }) => {
  let cObject = "";
  let bRequired = GEST0004.BANOBLXX === "SI" ? true : false;
  let cLabel = bRequired ? GEST0004.METDESXX + " *" : GEST0004.METDESXX;
  let objectMetadata = { ...GEST0004 };
  switch (objectMetadata.METTIPXX) {
    case "BOOLEANO":
      cObject = (
        <Form.Check
          type="checkbox"
          name={objectMetadata.METIDXXX}
          label={cLabel}
          required={bRequired}
          onChange={(e) => {
            const key = objectMetadata.METIDXXX;
            setJsonmetx(key, e.target.value);
          }}
        ></Form.Check>
      );
      break;
    case "NUMERO":
    case "TEXTO":
      let type = objectMetadata.METTIPXX === "NUMERO" ? "number" : "text";
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Form.Control
            value={inputValue[objectMetadata.METIDXXX]}
            type={type}
            name={objectMetadata.METIDXXX}
            required={bRequired}
            onChange={(e) => {
              if (e.target.value !== "") {
                const key = objectMetadata.METIDXXX;
                setJsonmetx(key, e.target.value);
              }
            }}
          ></Form.Control>
        </>
      );
      break;
    case "FECHA":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Form.Control
            value={inputValue[objectMetadata.METIDXXX]}
            required={bRequired}
            type="date"
            name={objectMetadata.METIDXXX}
            onChange={(e) => {
              if (e.target.value !== "") {
                const key = objectMetadata.METIDXXX;
                setJsonmetx(key, e.target.value);
                e.target.value = e.target.value.split("/").reverse().join("-");
              }
            }}
          ></Form.Control>
        </>
      );
      break;
    case "LISTASELECCION":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Form.Select
            value={inputValue[objectMetadata.METIDXXX]}
            required={bRequired}
            name={objectMetadata.METIDXXX}
            onChange={(e) => {
              const key = objectMetadata.METIDXXX;
              setJsonmetx(key, e.target.value);
            }}
          >
            <option value={""}>[SELECCIONE]</option>
            {objectMetadata.METOPXXX.split(",").map((option, key) => {
              return (
                <option value={option} key={`option-${GEST0004.METIDXXX}-${key}`}>
                  {option}
                </option>
              );
            })}
          </Form.Select>
        </>
      );
      break;

    case "LISTASELECCIONFUNCION":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            isMultiple={false}
            inputValue={inputValue}
            bRequired={bRequired}
            jsonmetx ={jsonmetx}
          />
        </>
      );
      break;
    case "LISTASELECCIONFUNCIONMULTIPLE":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            isMultiple={true}
            inputValue={inputValue}
            bRequired={bRequired}
            jsonmetx ={jsonmetx}
          />
        </>
      );
      break;
    case "HORA":
      cObject = (
        <>
          <Form.Label>{cLabel}</Form.Label>
          <Form.Control
            value={inputValue[objectMetadata.METIDXXX]}
            required={bRequired}
            type="time"
            name={objectMetadata.METIDXXX}
            onChange={(e) => {
              if (e.target.value !== "") {
                const key = objectMetadata.METIDXXX;
                setJsonmetx(key, e.target.value);
                e.target.value = e.target.value.split("/").reverse().join("-");
              }
            }}
          ></Form.Control>
        </>
      );
      break;
    default:
      cObject = "";
      break;
  }
  return cObject;
};

export default Metadata;
